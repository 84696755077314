import React, { useRef, useState, useEffect, useCallback } from 'react';
import "../../../CSS/Profile.css"
import toast from 'react-hot-toast';
import { NavLink } from 'react-router-dom';
import Footer from '../../footer';
import axios from 'axios';
// import { format } from 'date-fns'; // or import moment from 'moment';
import PdfViewer from '../../resumeViewer';
import Spinner from '../../Spinner';
const API_PORT = process.env.REACT_APP_API_PORT;


const Profile = (props) => {
  // const { isLoggedIn, setIsLoggedIn } = props;
  const UserEmail = localStorage.getItem('UserEmail').replace(/"/g, '');
  const [coverImage, setCoverImage] = useState(null);
  const [ProfilePic, setProfilePic] = useState(null);
  const [educationData, setEducationData] = useState([]);
  const [workExperienceData, setWorkExperienceData] = useState([]);
  const [projectList, setProjectList] = useState([]); // State for project data
  const [achievementList, setAchievementList] = useState([]);
  const [certificationList, setCertificationList] = useState([]);
  const [userName, setUserName] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [userResume, setUserResume] = useState(false);
  const [AboutMe, setAboutMe] = useState('');
  const [loading, setLoading] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [pdfPreviewUrl, setPdfPreviewUrl] = useState('');
  const inputRef = useRef(null);
  const input_img = useRef(null);

  // Trigger file input
  const triggerFileInput = () => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  const openChooseFile = () => {
    if (input_img.current) {
      input_img.current.click();
    } else {
      console.error('input_img is not attached to any DOM element');
    }
  };

  // ========================================================== Image Upload ==========================================================
  const handleChangeCover = async (event) => {
    const selectedFile = event.target.files[0];
    const ALLOWED_TYPES = ['image/png', 'image/jpeg', 'image/jpg'];

    if (selectedFile) {
      // Frontend file type validation
      if (!ALLOWED_TYPES.includes(selectedFile.type)) {
        toast.error('Invalid file type. Only PNG, JPEG, and JPG files are allowed.');
        return;
      }

      let reader = new FileReader();
      reader.onloadend = () => setCoverImage(reader.result);

      // Prepare form data for file upload
      const formData = new FormData();
      formData.append('profile_picture', selectedFile);
      formData.append('email', UserEmail);

      // Upload file to the server
      try {
        setLoading(true);
        const response = await axios.post(`${API_PORT}/upload-profile-picture`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

        if (response.data.success) {
          toast.success(response.data.message);

          // Revoke any previous object URL to avoid memory leaks
          if (coverImage) {
            URL.revokeObjectURL(coverImage);
          }
          setCoverImage(URL.createObjectURL(selectedFile)); // Update image preview
          fetchUserImage();
        } else {
          toast.error(response.data.message);
        }
      } catch (error) {
        const errorMessage = error.response?.data?.message || 'Error uploading profile picture';
        toast.error(errorMessage);
      }
      setLoading(false);
    }
  };

  // Get a user Image 
  const fetchUserImage = useCallback(async () => {
    try {
      // Make a GET request to the backend endpoint to fetch user image
      const response = await axios.get(`${API_PORT}/fetch_user_img`, {
        // Pass the email as a query parameter to the backend endpoint
        params: { email: UserEmail },
        // Include credentials such as cookies in the request
        withCredentials: true
      });
      // Check if the response indicates a successful fetch
      if (response.data.success) {
        // Update the user state with the fetched user image
        setCoverImage(response.data.userimg);
        setProfilePic(response.data.profilePicture);
      } else {
        // Log an error if the fetch was not successful
        // console.error('Error fetching user image:', response.data.message);
        toast.error(response.data.message);
      }
    } catch (error) {
      // Log any errors that occur during the fetch
      // console.error('Error fetching user image:', (error.response?.data?.message || 'Error uploading profile picture'));
    }
  }, [UserEmail]);

  // useEffect(() => {
  //   console.log(`${API_PORT}/fetch_user_img`);
  // }, [])

  // Get a UserName and Email
  const fetchUserProfileData = useCallback(async () => {
    try {
      const response = await axios.get(`${API_PORT}/fetch-user-data`, {
        params: { email: UserEmail }
      });

      if (response.data.success) {
        const data = response.data.results[0];
        setUserName(`${data.First_name} ${data.Last_name}`);
        setUserEmail(data.Email);
        if (data.resume_file) {
          setUserResume(true);
        }
      } else {
        // console.error('Error fetching user profile data:', response.data.message);
        toast.error(response.data.message);
      }
    } catch (error) {
      // console.error('Error fetching user profile data:', error);
      toast.error(error.response?.data?.message);
    }
  }, [UserEmail]);

  // ========================================================== Resume Upload ==========================================================
  const handleResumeUpload = async (event) => {
    const file = event.target.files[0];
    // Check if file exists
    if (file) {
      // Check if file is a PDF
      if (file.type !== 'application/pdf') {
        toast.error('Please upload a valid PDF file');
        return;
      }

      // Check if file size is 2 MB or less
      if (file.size > 2 * 1024 * 1024) { // 2 MB in bytes
        toast.error('File size should be 2 MB or less');
        return;
      }
      const fileUrl = URL.createObjectURL(file);
      setPdfPreviewUrl(fileUrl);
      // console.log(pdfPreviewUrl);

      const formData = new FormData();
      formData.append('resume', file);
      formData.append('email', UserEmail);

      try {
        setLoading(true);
        const response = await axios.post(`${API_PORT}/upload-resume`, formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });
        if (response.data.success) {
          toast.success('Resume uploaded successfully');
        } else {
          toast.error(response.data.message);
        }
      } catch (error) {
        console.error('Error uploading resume:', error);
        toast.error('Error uploading resume');
      }
    } else {
      toast.error('No file selected');
    }
    setLoading(false);
  };

  // Get a User Bio
  const handleDataFetch = useCallback(async () => {
    try {
      const resp = await axios.get(`${API_PORT}/fetch-user-data?email=${UserEmail}`);
      if (resp.data.success) {
        const data = resp.data.results[0];
        setAboutMe(data.Bio)
      }
      // console.log(resp);
    } catch (err) {
      toast.error(err.response?.data?.message);
    }
  }, [UserEmail]);

  const fetchEducationData = async () => {
    const UserEmail = localStorage.getItem('UserEmail').replace(/"/g, '');

    try {
      const response = await fetch(`${API_PORT}/get-education`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'x-user-email': UserEmail
        }
      });

      const data = await response.json();

      if (data.success) {
        setEducationData(data.data);
      } else {
        // console.error('Failed to fetch education details:', data.message);
        toast.error(data.message);
      }
    } catch (error) {
      // console.error('Error:', error);
    }
  };

  const fetchWorkExperience = async () => {
    const UserEmail = localStorage.getItem('UserEmail').replace(/"/g, '');

    try {
      const response = await axios.get(`${API_PORT}/get-workexp?email=${UserEmail}`);
      if (response.data.success) {
        setWorkExperienceData(response.data.workExperienceDetails);
        // console.log('Message:', response.data.message);
      } else {
        console.error('Error fetching work experience:', response.data.message);
      }
    } catch (error) {
      if (error.response) {
        // Server responded with a status other than 2xx
        console.error('Error fetching work experience:', error.response.data.message);
      } else if (error.request) {
        // Request was made but no response was received
        console.error('No response received:', error.request);
      } else {
        // Something else happened
        console.error('Error:', error.message);
      }
    }
  };


  const fetchProjects = async () => {
    const UserEmail = localStorage.getItem('UserEmail').replace(/"/g, '');

    try {
      const response = await axios.get(`${API_PORT}/get-projects?email=${UserEmail}`);
      if (response.data.success) {
        setProjectList(response.data.projects);
      } else {
        console.error('Error fetching projects:', response.data.message);
      }
    } catch (error) {
      console.error('Error fetching projects:', error);
    }
  };


  const fetchAchievements = async () => {
    const UserEmail = localStorage.getItem('UserEmail').replace(/"/g, ''); // Remove double quotes
    try {
      const response = await axios.get(`${API_PORT}/get-achievements?email=${UserEmail}`);
      if (response.data.success) {
        setAchievementList(response.data.achievements);
      } else {
        console.error('Error fetching achievements:', response.data.message);
      }
    } catch (error) {
      console.error('Error fetching achievements:', error);
    }
  };

  const fetchCertifications = async () => {
    const UserEmail = localStorage.getItem('UserEmail').replace(/"/g, ''); // Remove double quotes
    try {
      const response = await axios.get(`${API_PORT}/get-certifications?email=${UserEmail}`);
      if (response.data.success) {
        setCertificationList(response.data.certifications);
      } else {
        console.error('Error fetching certifications:', response.data.message);
      }
    } catch (error) {
      console.error('Error fetching certifications:', error);
    }
  };

  useEffect(() => {
    fetchEducationData();
    fetchWorkExperience();
    fetchAchievements();
    fetchCertifications();
    fetchProjects();
    fetchUserImage();
    fetchUserProfileData();
    handleDataFetch();
  }, [fetchUserImage, fetchUserProfileData, handleDataFetch]);

  return (
    <div>
      {loading && <Spinner />}
      <div className='profileDashboard'>
        <h1 className='profiletitle'>Profile</h1>
        <div className='box user-container mt-0'>
          <div className='user-avatar'>
            <span className="userProfileImage">
              <img src={ProfilePic ? ProfilePic : coverImage} alt="User Profile" className='profileImage' />
              <button onClick={openChooseFile} position="absolute" top={4} right={4} variant="ghost">
                <svg width="1.2em" fill="currentColor" viewBox="0 0 20 20" className='profile-change-icon'>
                  <path fillRule="evenodd" clipRule="evenodd" d="M4 5a2 2 0 00-2 2v8a2 2 0 002 2h12a2 2 0 002-2V7a2 2 0 00-2-2h-1.586a1 1 0 01-.707-.293l-1.121-1.121A2 2 0 0011.172 3H8.828a2 2 0 00-1.414.586L6.293 4.707A1 1 0 015.586 5H4zm6 9a3 3 0 100-6 3 3 0 000 6z" />
                </svg>
                <input ref={input_img} type="file" onChange={handleChangeCover} hidden />
              </button>
            </span>
          </div>
          <div className='profile-details'>
            <span className='user-name'>{userName}</span>
            <span className='user-email'>{userEmail}</span>
          </div>
          <div className='profile-info'>
            {
              userResume &&
              <div className='mb-2'>
                <PdfViewer email={userEmail} />
              </div>
            }
            <input
              type="file"
              accept=".pdf"
              className='resume-upload'
              ref={inputRef}
              onChange={handleResumeUpload}
              style={{ display: 'none' }} // Hide the file input
            />
            <div className='buttons'>
              <button className='profile-button' onClick={triggerFileInput}>
                Update Resume
              </button>
              <NavLink to="/edit-profile" className='profile-button text-center no-underline'>Edit Profile</NavLink>
            </div>
          </div>
        </div>

        <div className='mt-5'>
          {AboutMe && <section className="box">
            <p className="heading">ABOUT ME</p>
            <p className="about-desc">{AboutMe}</p>
          </section>}
          {educationData.length !== 0 && <section className="box">
            <p className="heading">EDUCATION</p>
            <div className='education-list'>
              {educationData.map((edu, index) => (
                <div key={index} className='education-item'>
                  <p className='text-[1.2rem] font-semibold leading-4 text-[#002b36]'>Institute: {edu.Institute}</p>
                  <p className='text-[1rem] leading-3 text-[#002b36]'><span className='no-underline font-bold'>Degree:</span> {edu.Degree}</p>
                  <p className='text-[1rem] leading-3 text-[#002b36]'><span className='no-underline font-bold'>Start Year:</span> {edu.Start_year}</p>
                  <p className='text-[1rem] leading-3 text-[#002b36]'><span className='no-underline font-bold'>End Year:</span> {edu.End_year}</p>
                  <p className='text-[1rem] leading-3 text-[#002b36]'><span className='no-underline font-bold'>CGPA:</span> {edu.CGPA}</p>
                </div>
              ))}
            </div>
          </section>}

          {workExperienceData.length !== 0 && <section className="box">
            <p className="heading">WORK EXPERIENCE</p>
            <div className='education-list'>
              {workExperienceData.map((exp, index) => (
                <div key={index} className='experience-item'>
                  <p className='text-[1.2rem] font-semibold leading-4 text-[#002b36]'> <span className='no-underline font-bold'>Designation :</span>  {exp.designation}</p>
                  <p className='text-[1rem] leading-3 text-[#002b36]'> <span className='no-underline font-bold'>Organization :</span>  {exp.designation}</p>
                  <p className='text-[1rem] leading-3 text-[#002b36]'>{exp.start_year} - {exp.end_year}</p>
                </div>
              ))}
            </div>
          </section>}

          {projectList.length !== 0 && <section className="box">
            <p className="heading">PROJECTS</p>
            <div className='education-list'>
              {projectList.map((project, index) => (
                <div key={index} className='experience-item'>
                  <p className='text-[1.2rem] font-semibold leading-8 text-[#002b36]'>Title: {project.Title}</p>
                  <p className='text-[1rem] leading-8 text-[#002b36] font-serif'><span className='font-bold'>Description:</span> {project.Description}</p>
                  <p className='text-[1rem] leading-8 text-[#002b36] font-medium'><span className='font-bold'>Link:</span> <a href={project.ProjectLink} target='_blank' rel="noreferrer" className='no-underline'>{project.ProjectLink}</a></p>
                </div>
              ))}
            </div>
          </section>}

          {achievementList.length !== 0 && <section className="box">
            <p className="heading">ACHEIVEMENTS</p>
            <div className='education-list'>
              {achievementList.map((achievement, index) => (
                <div key={index} className='experience-item'>
                  <p className='text-[1.2rem] font-semibold leading-8 text-[#002b36]'><span className='font-bold'>Name:</span>  {achievement.A_C_Name}</p>
                  <p className='text-[1rem] leading-8 text-[#002b36] font-serif'><span className='font-bold'>Description:</span> {achievement.Description}</p>
                  <p className='text-[1rem] leading-8 text-[#002b36] font-medium'>
                    <span className='font-bold'>Link: </span>
                    <a href={achievement.Link} target='_blank' rel="noreferrer" className='no-underline'>{achievement.Link}</a></p>
                </div>
              ))}
            </div>
          </section>}

          {certificationList.length !== 0 && <section className="box">
            <p className="heading">CERTIFICATES</p>
            <div className='education-list'>
              {certificationList.map((certification, index) => (
                <div key={index} className='experience-item'>
                  <p className='text-[1.2rem] font-semibold leading-8 text-[#002b36]'><span className='no-underline font-bold'>Name:</span> {certification.A_C_Name}</p>
                  <p className='text-[1rem] leading-8 text-[#002b36] font-serif'><span className='font-bold'>Description:</span> {certification.Description}</p>
                  <p className='text-[1rem] leading-8 text-[#002b36] font-medium'><span className='font-bold'>Link: </span>
                    <a href={certification.Link} target='_blank' rel="noreferrer" className='no-underline'>{certification.Link}</a></p>
                </div>
              ))}
            </div>
          </section>}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Profile;
