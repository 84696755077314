import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { MdDeleteForever } from 'react-icons/md';
import { format } from 'date-fns'; // or import moment from 'moment';
const API_PORT = process.env.REACT_APP_API_PORT;


const FormTemplate = ({ formData, handleChange, handleSubmit, activeTab }) => {
  return (
    <form onSubmit={handleSubmit}>
      <div className='col-name'>
        <label>{activeTab === 'achievements' ? 'Achievement Name*' : 'Certification Name*'}</label>
        <input
          type="text"
          name="name"
          value={formData.name}
          onChange={handleChange}
          required
          className='input-fields-about'
          placeholder={`Enter your ${activeTab === 'achievements' ? 'achievement' : 'certification'} name`}
        />
      </div>
      <div className='row-name'>
        <div className='col-name'>
          <label>{activeTab === 'achievements' ? 'Achievement Date*' : 'Certification Date*'}</label>
          <input
            type="date"
            name="date"
            value={formData.date}
            onChange={handleChange}
            required
            className='input-fields'
            placeholder={`Enter your ${activeTab === 'achievements' ? 'achievement' : 'certification'} date`}
          />
        </div>
        <div className='col-name'>
          <label>{activeTab === 'achievements' ? 'Achievement Link*' : 'Certification Link*'}</label>
          <input
            type="text"
            name="link"
            value={formData.link}
            onChange={handleChange}
            required
            className='input-fields'
            placeholder={`Enter your ${activeTab === 'achievements' ? 'achievement' : 'certification'} link`}
          />
        </div>
      </div>
      <div className='col-name mt-6'>
        <label>{activeTab === 'achievements' ? 'Achievement Description*' : 'Certification Description*'}</label>
        <input
          type="text"
          name="description"
          value={formData.description}
          onChange={handleChange}
          required
          className='input-fields-about'
          placeholder={`Enter your ${activeTab === 'achievements' ? 'achievement' : 'certification'} description`}
        />
      </div>
      <button type="submit" className='update-details-btn'>
        {activeTab === 'achievements' ? 'Add Achievement' : 'Add Certification'}
      </button>
    </form>
  );
};

const formatDate = (isoDate) => {
  if (!isoDate) return ''; // Ensure date is not undefined or null
  return format(new Date(isoDate), 'MMMM d, yyyy'); // or return moment(isoDate).format('MMMM D, YYYY');
};

const Achievements = () => {
  const [achievementsData, setAchievementsData] = useState({
    name: '',
    date: '',
    link: '',
    description: '',
  });

  const [certificationsData, setCertificationsData] = useState({
    name: '',
    date: '',
    link: '',
    description: '',
  });

  const [isFormVisible, setFormVisible] = useState(false);
  const [activeTab, setActiveTab] = useState('achievements');
  const [achievementList, setAchievementList] = useState([]);
  const [certificationList, setCertificationList] = useState([]);

  useEffect(() => {
    fetchAchievements();
    fetchCertifications();
  }, []);

  const fetchAchievements = async () => {
    const UserEmail = localStorage.getItem('UserEmail').replace(/"/g, ''); // Remove double quotes
    try {
      const response = await axios.get(`${API_PORT}/get-achievements?email=${UserEmail}`);
      if (response.data.success) {
        setAchievementList(response.data.achievements);
      } else {
        console.error('Error fetching achievements:', response.data.message);
      }
    } catch (error) {
      console.error('Error fetching achievements:', error);
    }
  };

  const fetchCertifications = async () => {
    const UserEmail = localStorage.getItem('UserEmail').replace(/"/g, ''); // Remove double quotes
    try {
      const response = await axios.get(`${API_PORT}/get-certifications?email=${UserEmail}`);
      if (response.data.success) {
        setCertificationList(response.data.certifications);
      } else {
        console.error('Error fetching certifications:', response.data.message);
      }
    } catch (error) {
      console.error('Error fetching certifications:', error);
    }
  };

  const handleChange = (e, setData) => {
    const { name, value } = e.target;
    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e, data, setData, setList, list) => {
    e.preventDefault();
    const UserEmail = localStorage.getItem('UserEmail').replace(/"/g, ''); // Remove double quotes

    try {
      const response = await axios.post(`${API_PORT}/add-${activeTab}`, {
        ...data,
        email: UserEmail,
      }, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.data.success) {
        setList([...list, { ...data, ID: response.data.id }]);
        setData({
          name: '',
          date: '',
          link: '',
          description: '',
        });
        setFormVisible(false);
        fetchAchievements();
        fetchCertifications();
      } else {
        console.error(`Failed to add ${activeTab}:`, response.data.message);
      }
    } catch (error) {
      console.error(`Error adding ${activeTab}:`, error);
    }
  };

  const handleAddNewClick = () => {
    setFormVisible(true);
  };

  const handleDelete = async (id, list, setList) => {
    try {
      const response = await axios.delete(`${API_PORT}/delete-${activeTab}?id=${id}`, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.data.success) {
        const updatedList = list.filter(item => item.ID !== id);
        setList(updatedList);
      } else {
        console.error(`Failed to delete ${activeTab}:`, response.data.message);
      }
    } catch (error) {
      console.error(`Error deleting ${activeTab}:`, error);
    }
  };

  const renderList = (list, setList) => {
    return list.map((item, index) => (
      <div key={index} className='experience-item'>
        <div className='delete-icon' onClick={() => handleDelete(item.ID, list, setList)}>
          <MdDeleteForever size={20} />
        </div>
        <p className='text-[1.2rem] font-semibold leading-8 text-[#002b36]'>Name: {item.A_C_Name}</p>
        <p className='text-[1rem] leading-8 text-[#002b36] font-serif'>{item.Description}</p>
        <p className='text-[1rem] leading-8 text-[#002b36] font-serif'>{formatDate(item.Date)}</p>
        <p className='text-[1rem] leading-8 text-[#002b36] font-medium'>{item.Link}</p>
      </div>
    ));
  };

  return (
    <section className='box'>
      <p className="heading">Achievements & Certifications</p>
      <div className="account-type-container mb-8 mt-4">
        <button
          onClick={() => setActiveTab('achievements')}
          className={`button ${activeTab === 'achievements' ? 'button-student-active' : 'button-student-inactive'}`}
        >
          Achievements
        </button>
        <button
          onClick={() => setActiveTab('certifications')}
          className={`button ${activeTab === 'certifications' ? 'button-student-active' : 'button-student-inactive'}`}
        >
          Certifications
        </button>
      </div>

      <div>
        {activeTab === 'achievements' && renderList(achievementList, setAchievementList)}
        {activeTab === 'certifications' && renderList(certificationList, setCertificationList)}
      </div>

      {!isFormVisible && (
        <div className='addButton' onClick={handleAddNewClick}>
          Add New {activeTab === 'achievements' ? 'Achievement' : 'Certification'}
        </div>
      )}

      {isFormVisible && (
        <FormTemplate
          formData={activeTab === 'achievements' ? achievementsData : certificationsData}
          handleChange={(e) => handleChange(e, activeTab === 'achievements' ? setAchievementsData : setCertificationsData)}
          handleSubmit={(e) => handleSubmit(e, activeTab === 'achievements' ? achievementsData : certificationsData, activeTab === 'achievements' ? setAchievementsData : setCertificationsData, activeTab === 'achievements' ? setAchievementList : setCertificationList, activeTab === 'achievements' ? achievementList : certificationList)}
          activeTab={activeTab}
        />
      )}
    </section>
  );
};

export default Achievements;
