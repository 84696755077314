import React, { useState, useEffect } from 'react';
import axios from 'axios';
import JobList from './joblist';
import Jobfilter2 from './jobfilter2';
import '../../../CSS/opportunity.css';

const API_PORT = process.env.REACT_APP_API_PORT;

function Opportunity() {
  const [cards, setCards] = useState([]); // State to hold combined job and internship data
  const [filteredCards, setFilteredCards] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [locationTerm, setLocationTerm] = useState("");

  useEffect(() => {
    fetchJobsAndInternships();
  }, []);

  const fetchJobsAndInternships = async () => {
    try {
      const [jobsResponse, internshipsResponse] = await Promise.all([
        axios.get(`${API_PORT}/find-jobs`),
        axios.get(`${API_PORT}/find-internships`),
      ]);

      // Ensure data structure is as expected
      const jobsData = jobsResponse.data.data || [];
      const internshipsData = internshipsResponse.data.data || [];
      const combinedData = [...jobsData, ...internshipsData];

      console.log("Combined Data:", combinedData); // Log data to verify

      setCards(combinedData);
      setFilteredCards(combinedData);
    } catch (error) {
      console.error("Error fetching job/internship data:", error);
    }
  };

  const handleFilterChange = (selectedFilters) => {
    const {
      remote = [],
      jobType = [],
      status = [],
      applyDate = [],
    } = selectedFilters;

    const filtered = cards.filter((card) => {
      const jobTypeValue = card.job_type?.toLowerCase() || "";
      const statusValue = card.status?.toLowerCase() || "";
      const dateValue = card.date?.toLowerCase() || "";
      const profileValue = card.profile?.toLowerCase() || "";
      const companyNameValue = card.company_name?.toLowerCase() || "";
      const cityValue = card.city?.toLowerCase() || "";

      const matchesRemote =
        remote.length === 0 || remote.includes(jobTypeValue);

      const matchesJobType =
        jobType.length === 0 || jobType.includes(jobTypeValue);

      const matchesStatus =
        status.length === 0 || status.includes(statusValue);

      const matchesApplyDate =
        applyDate.length === 0 || applyDate.includes(dateValue);

      const matchesSearchTerm =
        searchTerm === "" ||
        profileValue.includes(searchTerm.toLowerCase()) ||
        companyNameValue.includes(searchTerm.toLowerCase());

      const matchesLocation =
        locationTerm === "" ||
        cityValue.includes(locationTerm.toLowerCase());

      return (
        matchesRemote &&
        matchesJobType &&
        matchesStatus &&
        matchesApplyDate &&
        matchesSearchTerm &&
        matchesLocation
      );
    });

    setFilteredCards(filtered);
  };

  return (
    <>
      <div className="opportunity_container">
        <div className="opp_search col-md-8 offset-md-2">
          <input
            type="text"
            placeholder="Job Title, Keyword"
            onChange={(e) => {
              setSearchTerm(e.target.value);
              handleFilterChange({
                remote: [],
                jobType: [],
                status: [],
                applyDate: [],
              });
            }}
          />
          <input
            type="text"
            placeholder="Enter Location"
            onChange={(e) => {
              setLocationTerm(e.target.value);
              handleFilterChange({
                remote: [],
                jobType: [],
                status: [],
                applyDate: [],
              });
            }}
          />
          <button onClick={() => handleFilterChange({})}>Find Job Now</button>
        </div>
      </div>

      <div className="flex flex-wrap md:flex-nowrap">
        <Jobfilter2 onFilterChange={handleFilterChange} />
        <div className="md:w-3/4 w-full">
          <JobList jobs={filteredCards} />
        </div>
      </div>
    </>
  );
}

export default Opportunity;
