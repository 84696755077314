import { BiHomeAlt2, BiSolidContact } from "react-icons/bi";
import { PiChatCircleBold } from "react-icons/pi";
import { TbReportSearch } from "react-icons/tb";
import { MdOutlinePostAdd } from "react-icons/md";
export const Routes = [
  {
    title: "Home",
    href: "/",
    Icon: BiHomeAlt2,
    userType : 0
  },
  {
    title: "About",
    href: "/about",
    Icon: PiChatCircleBold,
    userType: 0
  },
  {
    title: "Contact",
    href: "/contact",
    Icon: BiSolidContact,
    userType: 0
  },
  {
    title: "Opportunities",
    href: "/opportunities",
    Icon: TbReportSearch,
    userType: 1
  },
  {
    title: "Post a Job",
    href: "/jobpost",
    Icon: MdOutlinePostAdd,
    userType: 2
  }
];