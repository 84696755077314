import React from 'react';
import '../../../CSS/RectDash.css';
import { NavLink } from 'react-router-dom';
import { IoMdInformationCircleOutline } from "react-icons/io";
import { FaUser, FaListAlt, FaBriefcase } from "react-icons/fa";
import headimg from '../../../assets/rectdash.png';
import rectimg from '../../../assets/rectimg.png';
import logo1 from '../../../assets/google.png';
import logo2 from '../../../assets/microsoft.png';
import logo3 from '../../../assets/fedex.png';
import logo4 from '../../../assets/oracle.png';
import logo5 from '../../../assets/amazon.png';

const RectDash = () => {
  return (
    <>
      <div className="head">
        <img src={headimg} alt="heading" />
      </div>
      <div className="section">
        <div className="search-container">
          <div className="search-box">
            <div className="heading">
              Search candidate database
              <IoMdInformationCircleOutline
                className="info-icon"
                title="Get a list of the available candidates before posting a job. Just enter the city and job title you’re looking to hire for."
              />
            </div>
            <div className="input-container">
              <div className="input-group">
                <div className="label">Select job title</div>
                <input type="text" placeholder="Eg. Back Office job" className="input" />
              </div>
              <div className="input-group">
                <div className="label">Select City</div>
                <input type="text" placeholder="Pick Your City" className="input" />
              </div>
              <button className="search-button">Search</button>
            </div>
          </div>
        </div>
      </div>
      <p className="subtitle">
        10L+ top companies trust TalentGrow Global for their hiring needs
      </p>
      <div className="logo-container">
        <img src={logo1} alt="google" className="logo" />
        <img src={logo2} alt="microsoft" className="logo" />
        <img src={logo3} alt="fedex" className="logo" />
        <img src={logo4} alt="oracle" className="logo" />
        <img src={logo5} alt="amazon" className="logo" />
      </div>
    <div className="container">
    <div className="left-section">
        <img src={rectimg} alt="jobs post" />
      </div>
      <div className="right-section">
        <h2>Get started with TalentGrow Global</h2>
        <h3>Post a job in minutes</h3>
        <p>Revolutionize your hiring with our AI-powered algorithm.</p>
        <ul className="features-list">
          <li>Get unlimited applications</li>
          <li>10x higher relevancy</li>
          <li>Simplified job posting</li>
        </ul>
        <NavLink to="/jobpost" className="post-job-button">
          Post a job now
        </NavLink>
      </div>
      
    </div>
    
    <div className="steps-container">
        <div className="step-card">
          <FaUser className="step-icon" />
          <h3>Register your account</h3>
          <p>By registering an account, you can use our basic features to find hundreds of exciting jobs.</p>
        </div>
        <div className="step-card">
          <FaListAlt className="step-icon" />
          <h3>Select your preference</h3>
          <p>Discover candidates most relevant to you by experience level, location, job type, etc.</p>
        </div>
        <div className="step-card">
          <FaBriefcase className="step-icon" />
          <h3>Wait for candidate</h3>
          <p>As a recruiter, approach the best candidates from the database to start the hiring process.</p>
        </div>
      </div>
    </>
  );
};

export default RectDash;
