import React, { useState, useEffect, useCallback } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { AiOutlineEyeInvisible, AiOutlineEye } from "react-icons/ai";
import { toast } from "react-hot-toast";
import "../CSS/Signup.css";
import SignupImage from "../assets/signup-image.png";
import { FcGoogle } from "react-icons/fc";
import { auth, provider, signInWithPopup } from "../components/firebase";
import Spinner from '../components/Spinner';

const API_PORT = process.env.REACT_APP_API_PORT;
export default function Signup() {
  const [accountType, setAccountType] = useState('student');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPass, setShowConfirmPass] = useState(false);
  const [loading, setLoading] = useState(false);
  const [otpSent, setOtpSent] = useState(false);
  const [otp, setOtp] = useState(new Array(6).fill(""));
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
  });

  function changeHandler(event) {
    if (otpSent) return; // Prevent changes after OTP is sent
    setFormData(prev => ({
      ...prev,
      [event.target.name]: event.target.value,
    }));
  }

  const handleOtpChange = (element, index) => {
    if (isNaN(element.value)) return false;

    const newOtp = [...otp];
    newOtp[index] = element.value;
    setOtp(newOtp);

    if (element.nextSibling && element.value) {
      element.nextSibling.focus();
    }
  };

  const handleKeyDown = (event, index) => {
    if (event.key === 'Backspace') {
      const newOtp = [...otp];
      newOtp[index] = '';
      setOtp(newOtp);

      if (event.target.previousSibling) {
        event.target.previousSibling.focus();
      }
    }
  };

  const sendOtp = async () => {
    try {
      setLoading(true);
      const response = await fetch(`${API_PORT}/sendotp`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email: formData.email }),
      });
      const data = await response.json();
      setLoading(false);

      if (data.success) {
        setOtpSent(true);
        toast.success("OTP sent successfully");
      } else {
        toast.error(data.message || "Failed to send OTP");
      }
    } catch (error) {
      setLoading(false);
      setFormData('');
      toast.error("Failed to send OTP");
    }
  };

  async function submitHandler(e) {
    e.preventDefault();

    if (formData.password !== formData.confirmPassword) {
      toast.error("Passwords do not match");
      return;
    }

    const enteredOtp = otp.join('');
    if (enteredOtp.length !== 6) {
      toast.error("Please enter a valid 6-digit OTP");
      return;
    }

    try {
      setLoading(true);
      const verifyOtpResponse = await fetch(`${API_PORT}/verifyotp`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email: formData.email, otp: enteredOtp }),
      });

      const verifyOtpData = await verifyOtpResponse.json();

      if (verifyOtpData.success) {
        const image = `https://api.dicebear.com/5.x/initials/svg?seed=${formData.firstName} ${formData.lastName}`;

        const user = {
          userType: accountType === "student" ? 1 : 2,
          firstName: formData.firstName,
          lastName: formData.lastName,
          email: formData.email,
          password: formData.password,
          image: image
        };

        try {
          const response = await fetch(`${API_PORT}/signup`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(user),
            credentials: 'include'
          });
          const data = await response.json();
          setLoading(false);

          if (data.success) {
            toast.success("Account Created");
            navigate("/login");
          } else {
            toast.error(data.message || "Something went wrong");
          }
        } catch (error) {
          toast.error("Failed to create account");
        }

      } else {
        toast.error(verifyOtpData.message);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      navigate("/signup");
      toast.error("Failed to verify OTP");
    }
  }

  const signInWithGoogle = async () => {
    try {
      setFormData({
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        confirmPassword: "",
      });
      const result = await signInWithPopup(auth, provider);
      const tokenId = await result.user.getIdToken();
      const displayName = result.user.displayName || '';
      const [firstName, lastName = ''] = displayName.split(' ');

      const user = {
        userType: accountType === "student" ? 1 : 2,
        firstName,
        lastName,
        email: result.user.email,
        tokenId // Send the ID token to the back-end
      };

      const response = await fetch(`${API_PORT}/googleSignup`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(user),
        credentials: 'include'
      });

      const data = await response.json();

      if (data.success) {
        toast.success("Account Created");
        navigate("/login");
      } else {
        toast.error(data.message || "Something went wrong");
      }
    } catch (error) {
      console.error(error);
      toast.error("Failed to sign in with Google");
    }
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    if (otpSent) {
      submitHandler(e);
    } else {
      sendOtp();
    }
  };

  return (
    <div className="w-full h-full relative">
      {loading && <Spinner />}
      <div className="flex-center-signup">
        <div>
          <img src={SignupImage} alt="" className='login-img-signup' />
        </div>
        <div className="form-container-signup">
          <div className="account-type-container">
            <button
              onClick={() => !otpSent && setAccountType("student")}
              className={`button ${accountType === "student" ? "button-student-active" : "button-student-inactive"}`}
              disabled={otpSent}>
              Student
            </button>
            <button
              onClick={() => !otpSent && setAccountType("employer")}
              className={`button ${accountType === "employer" ? "button-employer-active" : "button-employer-inactive"}`}
              disabled={otpSent}>
              Employer
            </button>
          </div>
          <div className="form-content-signup">
            <h1 className="form-title-signup">
              {accountType === "student" ? <span>Register your Student account</span> : <span>Register your Employer account</span>}
            </h1>
            <form className="form-signup">
              <div className="name-signup">
                <div className='label-signup'>
                  <label className="label-text-signup">
                    <p>First Name <sup className="text-pink-600">*</sup></p>
                    <input
                      onChange={changeHandler}
                      type="text"
                      name="firstName"
                      value={formData.firstName}
                      className="input-signup"
                      placeholder="Enter First Name"
                      required
                      disabled={otpSent}
                    />
                  </label>
                </div>
                <div className='label-signup'>
                  <label className="label-text-signup">
                    <p>Last Name <sup className="text-pink-600">*</sup></p>
                    <input
                      onChange={changeHandler}
                      name="lastName"
                      type="text"
                      value={formData.lastName}
                      className="input-signup"
                      placeholder="Enter Last Name"
                      required
                      disabled={otpSent}
                    />
                  </label>
                </div>
              </div>
              <div className='label-signup'>
                <label className="label-text-signup">
                  <p>Your email <sup className="text-pink-600">*</sup></p>
                  <input
                    onChange={changeHandler}
                    type="email"
                    name="email"
                    value={formData.email}
                    className="input-signup"
                    placeholder="John@gmail.com"
                    required
                    disabled={otpSent} />
                </label>
              </div>
              <div className="name-signup">
                <div className='label-signup'>
                  <label htmlFor="password" className="label-text-signup relative">
                    <p>Password <sup className="text-pink-600">*</sup></p>
                    <input
                      onChange={changeHandler}
                      type={showPassword ? "text" : "password"}
                      value={formData.password}
                      name="password"
                      placeholder="••••••••"
                      className="input-signup"
                      required
                      disabled={otpSent} />
                    <span onClick={() => setShowPassword(!showPassword)} className="eye-icon-signup">
                      {showPassword ? <AiOutlineEyeInvisible fontSize={24} fill='#e95014' /> : <AiOutlineEye fontSize={24} fill='#e95014' />}
                    </span>
                  </label>
                </div>
                <div className='label-signup'>
                  <label htmlFor="password" className="label-text-signup relative">
                    <p>Confirm Password <sup className="text-pink-600">*</sup></p>
                    <input
                      onChange={changeHandler}
                      type={showConfirmPass ? "text" : "password"}
                      value={formData.confirmPassword}
                      name="confirmPassword"
                      placeholder="••••••••"
                      className="input-signup"
                      required
                      disabled={otpSent} />
                    <span onClick={() => setShowConfirmPass(!showConfirmPass)} className="eye-icon-signup">
                      {showConfirmPass ? <AiOutlineEyeInvisible fontSize={24} fill='#e95014' /> : <AiOutlineEye fontSize={24} fill='#e95014' />}
                    </span>
                  </label>
                </div>
              </div>
              {otpSent &&
                <div>
                  <div>
                    {otp.map((data, index) => (
                      <input
                        className="sm:w-12 w-6 h-10 sm:h-12 mx-1 text-center border-b-2 border-gray-600 focus:outline-none text-black"
                        type="text"
                        name="otp"
                        maxLength="1"
                        key={index}
                        value={data}
                        onChange={e => handleOtpChange(e.target, index)}
                        onKeyDown={e => handleKeyDown(e, index)}
                        onFocus={e => e.target.select()}
                      />
                    ))}
                  </div>
                </div>
              }

              <button className="button-signup" type="submit" onClick={handleFormSubmit}>
                {otpSent ? "Verify and Register" : "Sign up"}
              </button>

              <div className="flex w-full items-center justify-center my-4 gap-x-2">
                <div className="h-[1px] w-full bg-primary-color"></div>
                <p className="font-medium text-primary-color leading-[1.375rem] mb-0">
                  OR
                </p>
                <div className="h-[1px] w-full bg-primary-color"></div>
              </div>

              <button
                onClick={signInWithGoogle}
                className={`button-signup-google`}>
                <FcGoogle size={20} />
                <span>Continue with google</span>
              </button>
              <p className="login-link-signup">
                Already have an Account
                <NavLink to="/login"> Login </NavLink> here
              </p>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
