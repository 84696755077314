import React from 'react';

const ComingSoon = () => {
  return (
    <div className="flex items-center justify-center h-screen bg-gradient-to-r from-pink-100 via-yellow-100 to-blue-100">
      <div className="text-center">
        <h1 className="text-6xl font-extrabold text-black">Coming Soon</h1>
        <p className="mt-4 text-lg text-black">In the meantime, Stay tuned. We are almost ready to launch.</p>
      </div>
    </div>
  );
};

export default ComingSoon;
