import React, { useState, useEffect, useCallback } from "react";
import { MdOutlineKeyboardArrowLeft, MdOutlineKeyboardArrowRight } from "react-icons/md";
import JobProto from "./jobProto";
import JobCardModal from "./jobCardModal";
import "./jobcard.css";

function Jobcard() {
  const [currentJob, setCurrentJob] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);

  const jobData = [
    { companyName: "FIS", jobProfile: "C++ & Mumps Developer", exp: "3 to 10 years", JobType: "WFH", location: "Remote", salaryMin: "80k", salaryMax: "120k" },
    { companyName: "Empower", jobProfile: "Financial Services", exp: "2 to 5 years", JobType: "WFH", location: "New York", salaryMin: "60k", salaryMax: "90k" },
    { companyName: "Nagarro", jobProfile: "Digital Product Engineering", JobType: "WFH", exp: "1 to 3 years", location: "San Francisco", salaryMin: "70k", salaryMax: "100k" },
    { companyName: "Actalent Services", jobProfile: "Engineering & Sciences", JobType: "WFH", exp: "5 to 8 years", location: "Los Angeles", salaryMin: "85k", salaryMax: "110k" },
    { companyName: "VXI Global", jobProfile: "Customer Service", exp: "0 to 2 years", JobType: "WFH", location: "Chicago", salaryMin: "50k", salaryMax: "70k" }
  ];

  const handleNext = useCallback(() => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % Math.max(1, jobData.length - 3));
  }, [jobData.length]);

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + Math.max(1, jobData.length - 3)) % Math.max(1, jobData.length - 3));
  };

  useEffect(() => {
    const timer = setInterval(handleNext, 5000); // 5 seconds interval
    return () => clearInterval(timer); // Clean up the interval on component unmount
  }, [handleNext]);

  const handleCardClick = (job) => setCurrentJob(job);

  const handleCloseModal = () => setCurrentJob(null);

  return (
    <>
      <div className="jobcard-container">
        <button onClick={handlePrev} className="arrow-button">
          <MdOutlineKeyboardArrowLeft className="text-3xl" />
        </button>

        <div className="job-cards-wrapper">
          {jobData.slice(currentIndex, currentIndex + 4).map((job, index) => (
            <div key={index} className="job-card-container">
              <JobProto job={job} onClick={() => handleCardClick(job)} />
            </div>
          ))}
        </div>

        <button onClick={handleNext} className="arrow-button">
          <MdOutlineKeyboardArrowRight className="text-3xl" />
        </button>
      </div>

      {currentJob && (
        <div className="modal fade show d-block" tabIndex="-1">
          <div className="modal-dialog modal-dialog-centered modal-xl">
            <div className="modal-content bg-gray-100">
              <div className="modal-header" style={{ backgroundColor: '#e95014' }}>
                <button type="button" className="btn-close" aria-label="Close" style={{ filter: 'invert(1)' }} onClick={handleCloseModal}></button>
              </div>
              <div className="modal-body">
                <JobCardModal job={currentJob} handleCloseModal={handleCloseModal} />
              </div>
              <div className="modal-footer" style={{ backgroundColor: '#e95014' }}>
                <button type="button" className="apply-button">Apply</button>
                <button type="button" className="save-button">Save for Later</button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Jobcard;
