const SkillList = [
  { value: "React", label: "React" },
  { value: "Rust", label: "Rust" },
  { value: "JavaScript", label: "JavaScript" },
  { value: "Angular", label: "Angular" },
  { value: "Node.js", label: "Node.js" },
  { value: "Python", label: "Python" },
  { value: "Java", label: "Java" },
  { value: "C++", label: "C++" },
  { value: "C#", label: "C#" },
  { value: "PHP", label: "PHP" },
  { value: "Swift", label: "Swift" },
  { value: "Kotlin", label: "Kotlin" },
  { value: "Ruby", label: "Ruby" },
  { value: "Go", label: "Go" },
  { value: "TypeScript", label: "TypeScript" },
  { value: "SQL", label: "SQL" },
  { value: "HTML", label: "HTML" },
  { value: "CSS", label: "CSS" },
  { value: "Sass", label: "Sass" },
  { value: "Less", label: "Less" },
  { value: "Vue.js", label: "Vue.js" },
  { value: "Django", label: "Django" },
  { value: "Flask", label: "Flask" },
  { value: "Spring Boot", label: "Spring Boot" },
  { value: "GraphQL", label: "GraphQL" },
  { value: "Firebase", label: "Firebase" },
  { value: "AWS", label: "AWS" },
  { value: "Azure", label: "Azure" },
  { value: "Docker", label: "Docker" },
  { value: "Kubernetes", label: "Kubernetes" },
  { value: "TensorFlow", label: "TensorFlow" },
  { value: "PyTorch", label: "PyTorch" },
  { value: "Pandas", label: "Pandas" },
  { value: "NumPy", label: "NumPy" },
  { value: "Scikit-learn", label: "Scikit-learn" },
  { value: "Redux", label: "Redux" },
  { value: "MobX", label: "MobX" },
  { value: "Tailwind CSS", label: "Tailwind CSS" },
  { value: "Bootstrap", label: "Bootstrap" },
  { value: "Material-UI", label: "Material-UI" },
  { value: "jQuery", label: "jQuery" },
  { value: "Express.js", label: "Express.js" },
  { value: "MongoDB", label: "MongoDB" },
  { value: "MySQL", label: "MySQL" },
  { value: "PostgreSQL", label: "PostgreSQL" },
  { value: "Redis", label: "Redis" },
  { value: "Elasticsearch", label: "Elasticsearch" },
  { value: "Git", label: "Git" },
  { value: "CI/CD", label: "CI/CD" },
  { value: "Linux", label: "Linux" },
  { value: "Agile", label: "Agile" },
  { value: "Scrum", label: "Scrum" },
  { value: "Project Management", label: "Project Management" },
  { value: "UI/UX Design", label: "UI/UX Design" },
  { value: "Figma", label: "Figma" },
  { value: "Adobe XD", label: "Adobe XD" },
  { value: "Sketch", label: "Sketch" },
  { value: "Blender", label: "Blender" },
  { value: "Unity", label: "Unity" },
  { value: "Unreal Engine", label: "Unreal Engine" },
  { value: "Communication", label: "Communication" },
  { value: "Teamwork", label: "Teamwork" },
  { value: "Problem-Solving", label: "Problem-Solving" },
  { value: "Time Management", label: "Time Management" },
  { value: "Leadership", label: "Leadership" },
  { value: "Critical Thinking", label: "Critical Thinking" },
  { value: "Public Speaking", label: "Public Speaking" },
  { value: "Customer Service", label: "Customer Service" },
  { value: "Sales", label: "Sales" },
  { value: "Marketing", label: "Marketing" },
  { value: "Project Management", label: "Project Management" },
  { value: "Adaptability", label: "Adaptability" },
  { value: "Creativity", label: "Creativity" },
  { value: "Networking", label: "Networking" },
  { value: "Negotiation", label: "Negotiation" },
  { value: "Technical Writing", label: "Technical Writing" },
  { value: "Teaching", label: "Teaching" },
  { value: "Foreign Languages", label: "Foreign Languages" },
  { value: "Research", label: "Research" },
  { value: "Data Analysis", label: "Data Analysis" },
  { value: "Excel", label: "Excel" },
  { value: "Word", label: "Word" },
];

export default SkillList;