import React, { useCallback, useEffect, } from 'react';
import '../CSS/about.css'; // Import CSS file for styling
import Aboutimg from '../assets/about_img.png'; // Import image asset
import AboutTG from '../assets/About-TalentGro.png'; // Import image asset
import Footer from '../components/footer';
import axios from 'axios'; // Import axios




const Card = ({ title, content, icon, tit, cont }) => {
  return (
    <div className="card ">
      <div className="card-title">{title}</div>
      <div className="card-content">{content}</div>
      <div className="val">
        <div className="val-icon">{icon}</div>
        <div className="val-title">{tit}</div>
        <div className="val-content">{cont}</div>
      </div>
    </div>
  );
};

const About = ({ isLoggedIn, setIsLoggedIn }) => {
  // console.log(isLoggedIn,"about ");

  return (
    <div>
      <section className="about">
        <div className="about-heading">
          <h1>About Us</h1>
        </div>
        <div className="about-container">

          <div className="about-content">
            <br />
            <h3>Welcome to TALENTGRO GLOBAL</h3>
            <p>
              A leading job matching and hiring platform dedicated to connecting you with new opportunities. As a global leader in career advancement, we prioritize job seekers by offering access to job searches, resume posting, company research, and more.
              <br />
              Harnessing the power of AI technology, TALENTGRO GLOBAL is revolutionizing the way job matching and hiring are done. Every day, we help millions of individuals find their ideal careers, making us the go-to destination for job seekers and employers alike.
              <br />
              Join TALENTGRO GLOBAL and take the next step in your professional journey with the world's premier job site. Your new opportunity awaits. 🚀
              <br />
            </p>
          </div>
          <div>
            <img src={Aboutimg} alt="about_us" className="about_image" />
          </div>
        </div>
      </section>

      <div className="app">
        <div className="card-container app-card-container">
          <Card title="🏆 Vission" content="We Strive to be our Customers' Guiding Light " />
          <Card title=" 🎯 Mission" content="Our Mission is to Transform Millions of Lives" />
        </div>
      </div>

      <div className="value">
        <h1 className="section-heading">Our Values</h1>
        <p className="section-subheading">
          At TalentGro, we believe in building a "Better Future" on a Foundation of Integrity,
          Collaboration, Excellence, and Innovation.
        </p>
        <div className="val-container">
          <Card
            icon="❤️"
            tit="Integrity"
            cont="Upholding honesty and transparency, we consider trust as the cornerstone of successful relationships."
          />
          <Card
            icon="🤝"
            tit="Collaboration"
            cont="Believing in teamwork, we closely collaborate with clients, families, and educational institutions for best outcomes."
          />
          <Card
            icon="🏅"
            tit="Excellence"
            cont="Committed to excellence, we ensure the highest standards in all our endeavors."
          />
          <Card
            icon="🔧"
            tit="Innovation"
            cont="We embrace change, continuously seeking innovative solutions to drive progress."
          />
        </div>
      </div>

      <div className="about-us-container">
        <div className="about-us-image">
          <img src={AboutTG} alt="About Us" />
        </div>
        <div className="about-us-content">
          <h1>What is <span className="highlight">TalentGro Global?</span></h1>
          <p>
            TalentGro Global is where Brilliance meets Career navigation! We collaborate and deliver to enable Corporates,
            Educational Institutions, Students and Individuals to achieve their human resource goals. Our expertise lies in
            providing comprehensive Profile building to enhance personal and professional growth.
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
};


export default About;
