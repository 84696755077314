import React, { useState, useEffect } from 'react';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import { AiOutlineEyeInvisible, AiOutlineEye } from 'react-icons/ai';
import '../CSS/Login.css';
import LoginImage from '../assets/login_Image.png';
import { FcGoogle } from 'react-icons/fc';
import { auth, provider, signInWithPopup } from '../components/firebase';
import axios from 'axios'; // Import Axios
import Spinner from '../components/Spinner';

const API_PORT = process.env.REACT_APP_API_PORT;
export default function Login(props) {
  const setIsLoggedIn = props.setIsLoggedIn;
  const isLoggedIn = props.isLoggedIn;
  // console.log(isLoggedIn,"abcd ");
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  // Set Axios defaults for credentials
  axios.defaults.withCredentials = true;

  useEffect(() => {
    if (isLoggedIn) {
      // console.log("After", isLoggedIn);
      navigate("/");
    }
  }, [isLoggedIn, navigate]);

  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });

  function changeHandler(event) {
    setFormData(prev => ({
      ...prev,
      [event.target.name]: event.target.value,
    }));
  }

  async function submitHandler(e) {
    e.preventDefault();

    const user = {
      email: formData.email,
      password: formData.password,
    };

    try {
      setLoading(true);
      const response = await axios.post(`${API_PORT}/login`, user, { withCredentials: true });
      const data = response.data;
      setLoading(false);
      // console.log("this is loginp page ", data)

      if (data.success) {
        toast.success(data.message);
        setIsLoggedIn(true);
        navigate('/');
        localStorage.setItem('UserEmail', JSON.stringify(user.email));
        localStorage.setItem('UserType', data.userType);

      } else {
        toast.error(data.message || 'Invalid email or password');
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
      toast.error(error?.response?.data?.message);
    }
  }

  const signInWithGoogle = async () => {
    try {
      // Clear the input fields
      setFormData({
        email: '',
        password: '',
      });

      const result = await signInWithPopup(auth, provider);
      // console.log(result.user);
      const token = await result.user.getIdToken();
      const response = await axios.post(`${API_PORT}/googlelogin`, { tokenId: token }, { withCredentials: true });

      if (response && response.data && response.data.success) {
        toast.success(response.data.message);
        setIsLoggedIn(true);
        localStorage.setItem('UserEmail', JSON.stringify(result.user.email));
        localStorage.setItem('UserType', response.data.userType);
        navigate('/');
      } else {
        toast.error('Google sign-in was unsuccessful. Please try again.');
      }
    } catch (error) {
      if (error.response && error.response.data) {
        toast.error(error.response.data.message);
      } else {
        toast.error('Google sign-in was canceled or an unknown error occurred.');
      }
    }
  };



  return (
    <div className="login-w-full login-h-full relative z-1">
      {loading && <Spinner />}
      <div className="login-flex-center">
        <div className="login-form-container">
          <div className="login-form-content">
            <h1 className="login-form-title">Sign in to your account</h1>

            <form className="login-form">
              <div className="login-label">
                <label className="login-label-text">
                  <p>
                    Your email
                    <sup className="login-text-pink-600">*</sup>
                  </p>
                  <input
                    onChange={changeHandler}
                    type="email"
                    name="email"
                    value={formData.email}
                    className="login-input"
                    placeholder="John@gmail.com"
                    required
                  />
                </label>
              </div>

              <div className="login-label login-relative">
                <label htmlFor="password" className="login-label-text relative">
                  <p>
                    Password
                    <sup className="login-text-pink-600">*</sup>
                  </p>
                  <input
                    onChange={changeHandler}
                    type={showPassword ? 'text' : 'password'}
                    value={formData.password}
                    name="password"
                    placeholder="••••••••"
                    className="login-input"
                    required
                  />
                  <span onClick={() => setShowPassword(!showPassword)} className="login-eye-icon">
                    {showPassword ? <AiOutlineEyeInvisible fontSize={24} fill="#e95014" /> : <AiOutlineEye fontSize={24} fill="#e95014" />}
                  </span>
                </label>
                <Link to="/forgot-password">
                  <p className="mt-1 ml-auto max-w-max text-xs text-blue-600">
                    Forgot Password
                  </p>
                </Link>
              </div>

              <button type="submit" className="login-signin-button" onClick={submitHandler}>
                Sign in
              </button>

              <div className="flex w-full items-center justify-center my-4 gap-x-2">
                <div className="h-[1px] w-full bg-primary-color"></div>
                <p className="font-medium text-primary-color leading-[1.375rem] mb-0">OR</p>
                <div className="h-[1px] w-full bg-primary-color"></div>
              </div>

              <button onClick={signInWithGoogle} className={`button-signup-google`}>
                <FcGoogle size={20} />
                <span>Continue with google</span>
              </button>

              <p className="login-signup-link">
                Don’t have an account yet?
                <NavLink to="/signup"> Sign up</NavLink>
              </p>
            </form>
          </div>
        </div>
        <div>
          <img src={LoginImage} alt="" className="login-img" />
        </div>
      </div>
    </div>
  );
}
