import React, { useState, useEffect } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import "./EditProfile.css";
import BasicInformationForm from './BasicInformation';
import EducationDetails from './EducationDetails';
import Footer from "../../../footer"
import WorkExperience from './WorkExperience';
import Projects from './Projects';
import Acheivements from './Acheivements';

const EditProfile = ({ isLoggedIn }) => {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState('basic');
const UserEmail= localStorage.getItem('UserEmail');
  useEffect(() => {
    if (!UserEmail) {
      navigate("/");
    }
  }, []);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  if (!isLoggedIn) {
    return null; // Render nothing if not logged in and navigating away
  }

  return (
    <div className='edit-profile'>
      <h1 className="title">Edit Profile</h1>
      <div className='profile-containar'>
        <div className=''>
          <div className='options grid md:grid-cols-1 sm:grid-cols-3 min-[350px]:grid-cols-2 grid-cols-1'>
            <button className={`tab ${activeTab === 'basic' ? 'active' : ''} option`} onClick={() => handleTabClick('basic')}>
              Basic Information
            </button>

            <button className={`tab ${activeTab === 'education' ? 'active' : ''} option`} onClick={() => handleTabClick('education')}>
              Education Details
            </button>

            <button className={`tab ${activeTab === 'work-experience' ? 'active' : ''} option`} onClick={() => handleTabClick('work-experience')}>
              Work Experience
            </button>

            <button className={`tab ${activeTab === 'projects' ? 'active' : ''} option`} onClick={() => handleTabClick('projects')}>
              Projects
            </button>

            <button className={`tab ${activeTab === 'achievments' ? 'active' : ''} option`} onClick={() => handleTabClick('achievments')}>
              Achievements
            </button>
          </div>
          <NavLink to={"/profile"} className="back-btn">
            <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M217.9 256L345 129c9.4-9.4 9.4-24.6 0-33.9-9.4-9.4-24.6-9.3-34 0L167 239c-9.1 9.1-9.3 23.7-.7 33.1L310.9 417c4.7 4.7 10.9 7 17 7s12.3-2.3 17-7c9.4-9.4 9.4-24.6 0-33.9L217.9 256z"></path>
            </svg>
            <span>Back to Profile</span>
          </NavLink>
        </div>

        <div className='details-form'>
          {activeTab === 'basic' && <BasicInformationForm />}
          {activeTab === 'education' && <EducationDetails />}
          {activeTab === 'work-experience' && <WorkExperience />}
          {activeTab === 'projects' && <Projects />}
          {activeTab === 'achievments' && <Acheivements />}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default EditProfile;
