import { useClickAway } from "react-use";
import { useRef, useEffect } from "react";
import { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { Squash as Hamburger } from "hamburger-react";
import { Routes } from "./Routes";
import { NavLink } from "react-router-dom";

const HamburgerMenu = () => {
  const [isOpen, setOpen] = useState(false);
  const ref = useRef(null);
  const UserType = JSON.parse(localStorage.getItem('UserType'));

  useClickAway(ref, () => setOpen(false));

  useEffect(() => {
    if (isOpen) {
      // Prevent scrolling when the menu is open
      document.body.style.overflow = "hidden";
    } else {
      // Allow scrolling when the menu is closed
      document.body.style.overflow = "";
    }

    // Cleanup function to reset overflow when component unmounts
    return () => {
      document.body.style.overflow = "";
    };
  }, [isOpen]);

  return (
    <div ref={ref} className="md:hidden">
      <Hamburger
        toggled={isOpen}
        toggle={setOpen}
        size={30}
        color='white'
        rounded
        label="Show menu"
      />
      <div className="">
        <AnimatePresence>
          {isOpen && (
            <motion.div
              initial={{ opacity: 0, y: -10 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -10 }}
              transition={{ duration: 0.3 }}
              className="fixed top-[3.6rem] right-0 z-[10000] bg-gradient-to-b from-[#f86b33] to-[#d45d26] rounded-lg shadow-lg p-1 w-screen"
            >
              <ul className="flex flex-col items-center justify-center gap-2">
                {Routes.map((route, idx) => {
                  const { href, title, Icon, userType } = route;
                  if(userType === 0 || userType === UserType)
                  return (
                    <motion.li
                      initial={{ scale: 0.9, opacity: 0 }}
                      animate={{ scale: 1, opacity: 1 }}
                      transition={{
                        type: "spring",
                        stiffness: 260,
                        damping: 20,
                        delay: 0.1 + idx / 10,
                      }}
                      key={title}
                      className="w-full p-2 rounded-lg hover:bg-[#d45d26] transition-colors flex"
                    >
                      <NavLink
                        onClick={() => setOpen(false)}
                        className="flex items-center justify-start gap-4 border-b w-full rounded-lg text-white no-underline"
                        to={href}
                      >
                        <Icon className="text-white text-xl" />
                        <span className="text-lg">{title}</span>
                      </NavLink>
                    </motion.li>
                  );
                })}
              </ul>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </div>
  );
};

export default HamburgerMenu;
