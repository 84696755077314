import React, { useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import axios from 'axios';
import Home from './Screens/Home';
import About from './Screens/about';
import Contact from './Screens/contact';
import Login from './Screens/Login';
import Signup from './Screens/Signup';
import Navbar from './components/Navbar';
import Profile from './components/users/student/Profile';
import EditProfile from './components/users/student/editProfile/EditProfile';
import Empprofile from './components/users/recruiter/Empprofile';
import EmpProfileData from './components/users/recruiter/EmpProfileData';
import JobPostForm from './components/users/recruiter/PostJobInternship';
import PostedJob from './components/users/recruiter/PostedJob';
import ForgotPassword from './components/ForgotPassword';
import UpdatePassword from './components/UpdatePassword';
import UserDash from "./components/users/student/UserDash"
import Applied from './components/users/student/applied';
import Opportunity from './components/users/student/opportunity';
import ComingSoon from './Utils/comingSoon';
import PrivateRoute from './Utils/PrivateRoute';

const API_PORT = process.env.REACT_APP_API_PORT;
function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const Useremail = localStorage.getItem("UserEmail");

  // Fetch initial data from backend API
  useEffect(() => {
    // Check login status
    axios.get(`${API_PORT}/login`, { withCredentials: true })
      .then(res => {

        if (res.data.loggedIn || Useremail) {
          setIsLoggedIn(true);
        } else {
          setIsLoggedIn(false);
        }
      })
      .catch(err => {
        console.log('Error fetching login status:', err);
      });
  }, [Useremail]); // Empty dependency array ensures useEffect runs only once on mount

  return (
    <div className="w-screen h-screen flex-col">
      <Navbar isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} />

      <Routes>
        <Route path="/" element={<Home isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} />} />
        <Route path="/about" element={<About isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} />} />
        <Route path="/contact" element={<Contact isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} />} />
        <Route path="/login" element={<Login isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/update-password/:id" element={<UpdatePassword />} />

        {/* Private routes */}
        <Route path="/profile" element={
          <PrivateRoute isLoggedIn={isLoggedIn}>
            <Profile />
          </PrivateRoute>
        } />

        <Route path="/Userdash" element={
          <PrivateRoute isLoggedIn={isLoggedIn}>
            <UserDash />
          </PrivateRoute>
        } />

        <Route path="/applied" element={
          true ?
            <PrivateRoute isLoggedIn={isLoggedIn}>
              <ComingSoon />
            </PrivateRoute>
            :
            <PrivateRoute isLoggedIn={isLoggedIn}>
              <Applied />
            </PrivateRoute>
        } />

        <Route path="/edit-profile" element={
          <PrivateRoute isLoggedIn={isLoggedIn}>
            <EditProfile isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} />
          </PrivateRoute>
        } />

        <Route path="/jobpost" element={
          <PrivateRoute isLoggedIn={isLoggedIn}>
            <JobPostForm />
          </PrivateRoute>
        } />

        <Route path="/Emp-profile" element={
          <PrivateRoute isLoggedIn={isLoggedIn}>
            <Empprofile />
          </PrivateRoute>
        } />

        <Route path="/edit-emp-profile" element={
          <PrivateRoute isLoggedIn={isLoggedIn}>
            <EmpProfileData />
          </PrivateRoute>
        } />

        <Route path="/posted-job" element={
          <PrivateRoute isLoggedIn={isLoggedIn}>
            <PostedJob />
          </PrivateRoute>
        } />

        <Route path="/opportunities" element={
          true ?
            <PrivateRoute isLoggedIn={isLoggedIn}>
              <ComingSoon />
            </PrivateRoute>
            :
            <PrivateRoute isLoggedIn={isLoggedIn}>
              <Opportunity />
            </PrivateRoute>
        } />
      </Routes>
    </div>
  );
}

export default App;
