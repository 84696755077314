import React, { useEffect, useState } from 'react';
import logo from "../assets/TalentGro-RetinaLogo.svg";
import { Link, NavLink, useNavigate } from 'react-router-dom';
import "../CSS/Navbar.css";
import { toast } from "react-hot-toast";
import { BiLogOut } from "react-icons/bi";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { Routes } from "./Routes";
import HamburgerMenu from './HamburgerMenu';
import { Menu } from '@mantine/core';
import { FaUserCircle } from 'react-icons/fa';
import { MdDoneAll } from 'react-icons/md';
import axios from 'axios';

const API_PORT = process.env.REACT_APP_API_PORT;
const Navbar = (props) => {
  const isLoggedIn = props.isLoggedIn;
  const setIsLoggedIn = props.setIsLoggedIn;  
  const [coverImage, setCoverImage] = useState(null);
  const [ProfilePic, setProfilePic] = useState(null);
  // const [user, setUser] = useState(null);
  const navigate = useNavigate();
  // Retrieve email from local storage
  const UserEmail = JSON.parse(localStorage.getItem('UserEmail'));
  const UserType = JSON.parse(localStorage.getItem('UserType'));


  const fetchUserImage = async () => {

    try {
      // Make a GET request to the backend endpoint to fetch user image
      const response = await axios.get(`${API_PORT}/fetch_user_img`, {
        // Pass the email as a query parameter to the backend endpoint
        params: { email: UserEmail },
        // Include credentials such as cookies in the request
        withCredentials: true
      });
      // Check if the response indicates a successful fetch
      if (response.data.success) {
        // Update the user state with the fetched user image
        setCoverImage(response.data.userimg);
        setProfilePic(response.data.profilePicture);
      } else {
        // Log an error if the fetch was not successful
        console.error('Error fetching user image:', response.data.message);
      }
    } catch (error) {
      // Log any errors that occur during the fetch
      console.error('Error fetching user image:', error);
    }
  };

  useEffect(() => {
    if (isLoggedIn && UserEmail)
      fetchUserImage();
  }, [isLoggedIn]); // Dependency array to run useEffect when isLoggedIn or UserEmail changes


  const handleLogout = async () => {
    try {
      const response = await axios.post(`${API_PORT}/logout`, {}, { withCredentials: true });
      if (response.data.success) {
        setIsLoggedIn(false);
        setCoverImage(null);
        localStorage.removeItem("UserEmail");
        localStorage.removeItem("token");
        localStorage.removeItem("UserType");
        toast.success("Logout Successfully");
        navigate('/login');
      } else {
        toast.error("Failed to logout");
      }
    } catch (error) {
      console.error('Error logging out:', error);
      toast.error("Failed to logout");
    }
  };
  return (
    <div className='w-full flex justify-between items-center px-6 h-[70px] bg-primary-color border-b-2 z-20'>
      <div>
        <HamburgerMenu />
      </div>
      <NavLink to="/">
        <img src={logo} alt="Logo" loading="lazy" style={{ height: '60px', width: 'auto' }} />
      </NavLink>

      <nav className='nav-small'>
        <ul className="flex justify-center items-center">
          {Routes.map((route, index) => {
            const { href, title, userType } = route;
            if (userType === 0 || userType === UserType)
            return (
              <li key={index}>
                <NavLink
                  to={href}
                  className="px-3 mx-4 text-[1.2rem] no-underline uppercase text-white"
                >
                  {title}
                </NavLink>
              </li>
            );
          })}

          {/* {
            UserType === 1 &&
            <li> <NavLink
              to={'/opportunities'}
              className="px-3 mx-4 text-[1.2rem] no-underline uppercase text-white"
            >
              Opportunities
            </NavLink>
            </li>
          } */}
          {/* {
            UserType === 2 &&
            <li> <NavLink
              to={'/jobpost'}
              className="px-3 mx-4 text-[1.2rem] no-underline uppercase text-white"
            >
              Post a Job
            </NavLink>
            </li>
          } */}
        </ul>
      </nav>

      <div className="nav-container">
        {!isLoggedIn && (
          <NavLink to="/login">
            <button className="login-button">
              Login
            </button>
          </NavLink>
        )}

        {!isLoggedIn && (
          <NavLink to="/signup">
            <button className="signup-button">
              Sign Up
            </button>
          </NavLink>
        )}

        {isLoggedIn && (
          <div className='flex items-center justify-center md:mr-5 lg:mr-7 mr-2 relative'>
            <div>
              <button onClick={handleLogout}>
                <BiLogOut data-tooltip-id="my-logout-tooltip" size={30} color='white' />
                <ReactTooltip
                  id="my-logout-tooltip"
                  place="bottom"
                  effect="solid"
                  className="custom-tooltip"
                  content="Logout"
                />
              </button>
            </div>
          </div>
        )}

        {isLoggedIn && (
          <div className='md:pr-5 lg:pr-7 pr-1'>
            <Menu trigger="hover" openDelay={100} closeDelay={400}>
              <Menu.Target>
                <img src={ProfilePic ? ProfilePic :coverImage } className='avatar' alt="" />
              </Menu.Target>

              <Menu.Dropdown className='menu-dropdown'>
                {
                  UserType === 1 ? <Link to="/profile">
                    <Menu.Item>
                      <div className='sub-menu'>
                        <FaUserCircle size={18} />
                        <span>My Profile</span>
                      </div>
                    </Menu.Item>
                  </Link>
                    : <Link to="/Emp-profile">
                      <Menu.Item>
                        <div className='sub-menu'>
                          <FaUserCircle size={18} />
                          <span>My Profile</span>
                        </div>
                      </Menu.Item>
                    </Link>
                }
                {
                  UserType === 1 ? <Link to="/applied">
                    <Menu.Item>
                      <div className='sub-menu'>
                        <MdDoneAll size={18} />
                        <span>Applied Jobs</span>
                      </div>
                    </Menu.Item>
                  </Link>
                    :
                    <Link to="/posted-job">
                      <Menu.Item>
                        <div className='sub-menu'>
                          <MdDoneAll size={18} />
                          <span>Posted Jobs</span>
                        </div>
                      </Menu.Item>
                    </Link>
                }
              </Menu.Dropdown>
            </Menu>
          </div>
        )}
      </div>
    </div>
  );
};

export default Navbar;
