import React, { useState } from "react";
import Select from 'react-select';
import skillsList from '../../SkillList'
import { toast } from 'react-hot-toast';
import { useNavigate } from "react-router-dom";
const API_PORT = process.env.REACT_APP_API_PORT;

const FormField = ({ label, type, name, value, onChange, placeholder, options, min }) => (
  <div>
    <label htmlFor={name} className="block text-sm font-medium text-gray-700">
      {label}
    </label>
    {type === "textarea" ? (
      <textarea
        name={name}
        value={value}
        onChange={onChange}
        rows="4"
        className="mt-1 block w-full p-2 border-2 rounded-md border-gray-300 focus:border-orange-500"
      ></textarea>
    ) : type === "radio" ? (
      <div className="flex space-x-4 mt-2">
        {options.map((option) => (
          <label key={option.value} className="flex items-center">
            <input
              type="radio"
              name={name}
              value={option.value}
              checked={value === option.value}
              onChange={onChange}
              className="h-4 w-4 text-orange-600 focus:ring-orange-500 border-gray-300"
            />
            <span className="ml-2 text-sm">{option.label}</span>
          </label>
        ))}
      </div>
    ) : (
      <input
        type={type}
        name={name}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        className="mt-1 block w-full p-2 border-2 rounded-md border-gray-300 focus:border-orange-500"
        min={min}
      />
    )}
  </div>
);

const UserEmail = localStorage.getItem('UserEmail');
const sanitizedUserEmail = UserEmail ? UserEmail.replace(/"/g, '') : null;



const JobPostForm = () => {
  const [opportunityType, setOpportunityType] = useState("job");
  const [formData, setFormData] = useState({
    companyName: "",
    jobProfile: "",
    jobDescription: "",
    jobAddress: "",
    internshipProfile: "",
    skillsRequired: [],
    jobType: "",
    internshipType: "",
    city: "",
    numberOfOpenings: "",
    internshipStartDate: "",
    internshipDuration: "",
    internsResponsibility: "",
    jobResponsibility: "",
    requirements: "",
    salaryMin: "",
    salaryMax: "",
    stipend: "",
    stipendMax: "",
    deadlineApply: "",
  });
  const Navigate = useNavigate('');


  const handleOpportunityTypeChange = (e) => {
    setOpportunityType(e.target.value);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSkillsChange = (selectedOptions) => {
    setFormData({
      ...formData,
      skillsRequired: selectedOptions ? selectedOptions.map(option => option.value) : [],
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    // Determine if it's a job or internship
    const postData = {
      opportunity_type: opportunityType,
      company_name: formData.companyName,
      profile: opportunityType === "job" ? formData.jobProfile : formData.internshipProfile,
      skills_required: JSON.stringify(formData.skillsRequired),
      job_type: opportunityType === "job" ? formData.jobType : formData.internshipType,
      city: formData.city,
      number_of_openings: formData.numberOfOpenings,
      start_date: opportunityType === "internship" ? formData.internshipStartDate : null,
      duration: opportunityType === "internship" ? formData.internshipDuration : null,
      responsibility: opportunityType === "job" ? formData.jobResponsibility : formData.internsResponsibility,
      requirements: formData.requirements,
      salary_min: opportunityType === "job" ? formData.salaryMin : null,
      salary_max: opportunityType === "job" ? formData.salaryMax : null,
      stipend: opportunityType === "internship" ? formData.stipend : null,
      stipend_max: opportunityType === "internship" ? formData.stipendMax : null,
      description: formData.jobDescription,
      address: formData.jobAddress,
      deadline_apply: formData.deadlineApply,
      user_email: sanitizedUserEmail
    };
    // console.log('sanitizedUserEmail', sanitizedUserEmail);
    // console.log("Here is posted data",postData)
    try {
      const response = await fetch(`${API_PORT}/post-job/internship`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(postData),
      });
  
      const data = await response.json();
  
      if (!response.ok) {
        console.error(`Failed to submit form data. Status: ${response.status} - ${response.statusText}`);
        console.error('Error details:', data);
        toast.error(data.message || 'Failed to submit form data.');
      } else {
        toast.success('Submission complete. We appreciate your contribution!');
        console.log("Form submitted successfully");
        Navigate('/')
      }
    } catch (error) {
      console.error("Failed to submit form data", error);
      toast.error("An error occurred while submitting the form.");
    }
  };
  

  
  const jobFields = [
    { label: "Company Name *", type: "text", name: "companyName", placeholder: "" },
    { label: "Job Profile *", type: "text", name: "jobProfile", placeholder: "e.g. Web Developer" },
    { label: "Skills Required *", type: "select", name: "skillsRequired", placeholder: "", isSkills: true },
    {
      label: "Job Type *",
      type: "radio",
      name: "jobType",
      options: [
        { label: "In Office", value: "in-office" },
        { label: "Hybrid", value: "hybrid" },
        { label: "Remote", value: "remote" },
      ],
    },
    { label: "City *", type: "text", name: "city", placeholder: "" },
    { label: "Number of Openings *", type: "number", name: "numberOfOpenings", placeholder: "", min: 0 },
    { label: "Minimum Salary", type: "number", name: "salaryMin", placeholder: "", min: 0 },
    { label: "Maximum Salary", type: "number", name: "salaryMax", placeholder: "", min: 0 },
    { label: "Job Description *", type: "textarea", name: "jobDescription", placeholder: "" },
    { label: "Job Address *", type: "textarea", name: "jobAddress", placeholder: "" },
    { label: "Job Responsibility *", type: "textarea", name: "jobResponsibility", placeholder: "" },
    { label: "Requirements *", type: "textarea", name: "requirements", placeholder: "" },
    { label: "Deadline to Apply *", type: "date", name: "deadlineApply", placeholder: "" },
  ];

  const internshipFields = [
    { label: "Company Name *", type: "text", name: "companyName", placeholder: "" },
    { label: "Internship Profile *", type: "text", name: "internshipProfile", placeholder: "e.g. Web Developer" },
    { label: "Skills Required *", type: "select", name: "skillsRequired", placeholder: "", isSkills: true },
    {
      label: "Internship Type *",
      type: "radio",
      name: "internshipType",
      options: [
        { label: "In Office", value: "in-office" },
        { label: "Hybrid", value: "hybrid" },
        { label: "Remote", value: "remote" },
      ],
    },
    { label: "City *", type: "text", name: "city", placeholder: "" },
    { label: "Number of Openings *", type: "number", name: "numberOfOpenings", placeholder: "", min: 0 },
    { label: "Stipend Minimum", type: "number", name: "stipend", placeholder: "", min: 0 },
    { label: "Stipend Maximum", type: "number", name: "stipendMax", placeholder: "", min: 0 },
    { label: "Internship Start Date *", type: "date", name: "internshipStartDate", placeholder: "" },
    { label: "Internship Duration (in months) *", type: "number", name: "internshipDuration", placeholder: "", min: 0 },
    { label: "Interns Responsibility *", type: "textarea", name: "internsResponsibility", placeholder: "" },
    { label: "Requirements *", type: "textarea", name: "requirements", placeholder: "" },
    { label: "Deadline to Apply *", type: "date", name: "deadlineApply", placeholder: "" },
  ];
  const fields = opportunityType === "job" ? jobFields : internshipFields;

  return (
    <div className="max-w-3xl mx-auto mt-3 p-6 bg-white rounded-lg shadow-lg md:p-8 lg:p-10">
      <h2 className="text-2xl font-semibold mb-4 text-orange-600">
        Post Internship/Job
      </h2>
      <form onSubmit={handleSubmit} className="space-y-4 mt-8">
        <div>
          <span className="block text-sm font-medium text-gray-700">
            Opportunity Type
          </span>
          <div className="flex space-x-4 mt-2 mb-2">
            <label className="flex items-center">
              <input
                type="radio"
                value="job"
                checked={opportunityType === "job"}
                onChange={handleOpportunityTypeChange}
                className="h-4 w-4 text-orange-600 focus:ring-orange-500 border-gray-300"
              />
              <span className="ml-2 text-sm">Job</span>
            </label>
            <label className="flex items-center">
              <input
                type="radio"
                value="internship"
                checked={opportunityType === "internship"}
                onChange={handleOpportunityTypeChange}
                className="h-4 w-4 text-orange-600 focus:ring-orange-500 border-gray-300"
              />
              <span className="ml-2 text-sm">Internship</span>
            </label>
          </div>
        </div>


        {fields.map((field) =>
          field.isSkills ? (
            <div key={field.name}>
              <label htmlFor={field.name} className="block text-sm font-medium text-gray-700">
                {field.label}
              </label>
              <Select
                isMulti
                name={field.name}
                options={skillsList}
                className="mt-1 block w-full"
                classNamePrefix="select"
                onChange={handleSkillsChange}
              />
            </div>
          ) : (
            <FormField
              key={field.name}
              label={field.label}
              type={field.type}
              name={field.name}
              value={formData[field.name]}
              onChange={handleInputChange}
              placeholder={field.placeholder}
              options={field.options}
              min={field.min}
            />
          )
        )}
        <div className="mt-6">
          <button
            type="submit"
            className="w-full py-2 px-4 bg-orange-600 text-white font-semibold rounded-md hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500"
          >
            Post Opportunity
          </button>
        </div>
      </form>
    </div>
  );
};

export default JobPostForm;
