import React from 'react';
import "../CSS/Spinner.css"

const Spinner = () => {
  return (
    <div className='loader'>

    </div>
  );
};

export default Spinner;