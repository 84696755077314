import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { MdDeleteForever } from 'react-icons/md';
const API_PORT = process.env.REACT_APP_API_PORT;



const Projects = () => {
  const [projectData, setProjectData] = useState({
    title: '',
    link: '',
    description: '',
  });

  const [isFormVisible, setFormVisible] = useState(false);
  const [projectList, setProjectList] = useState([]);

  useEffect(() => {
    fetchProjects();
  }, []);

  const fetchProjects = async () => {
    const UserEmail = localStorage.getItem('UserEmail').replace(/"/g, '');;
    try {
      const response = await axios.get(`${API_PORT}/get-projects?email=${UserEmail}`);
      if (response.data.success) {
        setProjectList(response.data.projects);
      } else {
        console.error('Error fetching projects:', response.data.message);
      }
    } catch (error) {
      console.error('Error fetching projects:', error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProjectData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const UserEmail = localStorage.getItem('UserEmail').replace(/"/g, '');

    try {
      const response = await axios.post(`${API_PORT}/add-project`, {
        ...projectData,
        email: UserEmail,
      });

      if (response.data.success) {
        setProjectList([...projectList, projectData]);
        setProjectData({ title: '', link: '', description: '' });
        setFormVisible(false);
        fetchProjects();
      } else {
        console.error('Failed to add project:', response.data.message);
      }
    } catch (error) {
      console.error('Error adding project:', error);
    }
  };

  const handleAddNewClick = () => {
    setFormVisible(true);
  };

  const handleDelete = async (projectId, index) => {
    const UserEmail = localStorage.getItem('UserEmail').replace(/"/g, '');
    try {
      const response = await axios.delete(`${API_PORT}/delete-project?id=${projectId}`, {
        headers: {
          'Content-Type': 'application/json',
          'x-user-email': UserEmail,
        },
      });

      if (response.data.success) {
        const updatedList = [...projectList];
        updatedList.splice(index, 1);
        setProjectList(updatedList);
      } else {
        console.error('Failed to delete project:', response.data.message);
      }
    } catch (error) {
      console.error('Error deleting project:', error);
    }
  };

  return (
    <section className='box'>
      <p className="heading">Projects</p>
      <div>
        {projectList.map((project, index) => (
          <div key={index} className='experience-item'>
            <div className='delete-icon' onClick={() => handleDelete(project.ProjectID, index)}>
              <MdDeleteForever size={20} />
            </div>
            <p className='text-[1.2rem] font-semibold leading-8 text-[#002b36]'>Title : {project.Title}</p>
            <p className='text-[1rem] leading-8 text-[#002b36] font-serif'>{project.Description}</p>
            <p className='text-[1rem] leading-8 text-[#002b36] font-medium'>{project.ProjectLink}</p>
          </div>
        ))}
      </div>

      {!isFormVisible && (
        <div className='addButton' onClick={handleAddNewClick}>
          Add New
        </div>
      )}
      {isFormVisible && (
        <form onSubmit={handleSubmit}>
          <div className='row-name'>
            <div className='col-name'>
              <label>Title*</label>
              <input
                type="text"
                name="title"
                value={projectData.title}
                onChange={handleChange}
                required
                className='input-fields'
                placeholder='Enter your project title'
              />
            </div>

            <div className='col-name'>
              <label>Project Link*</label>
              <input
                type="text"
                name="link"
                value={projectData.link}
                onChange={handleChange}
                required
                className='input-fields'
                placeholder='Enter your project link'
              />
            </div>
          </div>

          <div className='col-name mt-6'>
            <label>Description*</label>
            <input
              type="text"
              name="description"
              value={projectData.description}
              onChange={handleChange}
              required
              className='input-fields-about'
              placeholder='Enter your project description'
            />
          </div>

          <button type="submit" className='update-details-btn'>Add Project</button>
        </form>
      )}
    </section>
  );
};

export default Projects;
