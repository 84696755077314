import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { MdDeleteForever } from 'react-icons/md';
const API_PORT = process.env.REACT_APP_API_PORT;



const WorkExperience = () => {
  // Initializing work experience form data
  const [WorkExperienceData, setWorkExperienceData] = useState({
    designation: '',
    startYear: '',
    endYear: '',
    organizationName: '',
  });

  const [isFormVisible, setFormVisible] = useState(false);
  // Initializing experience list as an empty array
  const [experienceList, setExperienceList] = useState([]);

  useEffect(() => {
    fetchWorkExperience();
  }, []);

  const fetchWorkExperience = async () => {
    const userMail = localStorage.getItem('UserEmail')?.replace(/"/g, '');

    if (!userMail) {
      console.error('User email not found in localStorage');
      return;
    }

    try {
      const response = await axios.get(`${API_PORT}/get-workexp?email=${userMail}`);
      if (response.data.success) {
        // Updating experienceList state with fetched data
        setExperienceList(response.data.workExperienceDetails);
      } else {
        console.error('Error fetching work experience:', response.data.message);
      }
    } catch (error) {
      console.error('Error fetching work experience:', error.message);
    }
  };
  
  const handleChange = (e) => {
    const { name, value } = e.target;
    // Ensuring controlled inputs by updating state correctly
    setWorkExperienceData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const UserEmail = localStorage.getItem('UserEmail').replace(/"/g, '');
      console.log("heyyyy", UserEmail);
      const response = await axios.post(`${API_PORT}/add-workexp`, WorkExperienceData, {
        headers: {
          'Content-Type': 'application/json',
          'x-user-email': UserEmail,
        },
      });

      if (response.data.success) {
        // Update local state with the new experience data
        setExperienceList([...experienceList, WorkExperienceData]);
        // Reset form data
        setWorkExperienceData({
          designation: '',
          startYear: '',
          endYear: '',
          organizationName: '',
        });
        // Hide the form after submission
        setFormVisible(false);
        fetchWorkExperience();
      } else {
        console.error('Failed to add work experience:', response.data.message);
      }
    } catch (error) {
      console.error('Error adding work experience:', error);
    }
  };

  const handleAddNewClick = () => {
    setFormVisible(true);
  };

  const handleDelete = async (srno, index) => {
    try {
      const UserEmail = localStorage.getItem('UserEmail');
      const response = await axios.delete(`${API_PORT}/delete-workexp?srno=${srno}`, {
        headers: {
          'Content-Type': 'application/json',
          'x-user-email': UserEmail,
        },
      });

      if (response.data.success) {
        const updatedList = [...experienceList];
        updatedList.splice(index, 1);
        setExperienceList(updatedList);
      } else {
        console.error('Failed to delete work experience:', response.data.message);
      }
    } catch (error) {
      console.error('Error deleting work experience:', error);
    }
  };

  return (
    <section className='box'>
      <p className="heading">Work Experience</p>
      <div>
        {experienceList.map((exp, index) => (
          <div key={index} className='experience-item'>
            <div className='delete-icon' onClick={() => handleDelete(exp.srno, index)}>
              <MdDeleteForever size={20} />
            </div>
            <p className='text-[1.2rem] font-semibold leading-8 text-[#002b36]'>Designation : {exp.designation}</p>
            <p className='text-[1rem] leading-8 text-[#002b36] font-serif'>{exp.organization}</p>
            <p className='text-[1rem] leading-8 text-[#002b36] font-medium'>{exp.start_year} - {exp.end_year}</p>
          </div>
        ))}
      </div>

      {!isFormVisible && (
        <div className='addButton' onClick={handleAddNewClick}>
          Add New
        </div>
      )}
      {isFormVisible && (
        <form onSubmit={handleSubmit}>
          <div className='col-name'>
            <label>Designation*</label>
            <input
              type="text"
              name="designation"
              value={WorkExperienceData.designation} // Controlled input
              onChange={handleChange}
              required
              className='input-fields-about'
              placeholder='Enter your Designation'
            />
          </div>

          <div className='row-name'>
            <div className='col-name'>
              <label>Start Year*</label>
              <input
                type="number"
                name="startYear"
                value={WorkExperienceData.startYear} // Controlled input
                onChange={handleChange}
                required
                className='input-fields'
                placeholder='Enter the starting year'
              />
            </div>

            <div className='col-name'>
              <label>End Year*</label>
              <input
                type="number"
                name="endYear"
                value={WorkExperienceData.endYear} // Controlled input
                onChange={handleChange}
                required
                className='input-fields'
                placeholder='Enter the ending year'
              />
            </div>
          </div>

          <div className='col-name mt-6'>
            <label>Organization Name*</label>
            <input
              type="text"
              name="organizationName"
              value={WorkExperienceData.organizationName} // Controlled input
              onChange={handleChange}
              required
              className='input-fields-about'
              placeholder='Enter your organization name'
            />
          </div>

          <button type="submit" className='update-details-btn'>Add Experience</button>
        </form>
      )}
    </section>
  );
};

export default WorkExperience;
