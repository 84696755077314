import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/Page/TextLayer.css';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

const API_PORT = process.env.REACT_APP_API_PORT;

const PdfViewer = ({ email }) => {
  const [pdfFile, setPdfFile] = useState(null);
  const [numPages, setNumPages] = useState(null);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const modalRef = useRef(null);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);


  const fetchResume = async () => {
    try {
      const response = await axios.get(`${API_PORT}/fetch-resume`, {
        params: { email }
      });
      const fileURL = response.data.fileURL
      setPdfFile(fileURL);
    } catch (error) {
      console.error('Error fetching resume:', error);
    }
  };

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const pageWidth = windowWidth < 768 ? windowWidth * 0.9 : 768;

  const handleOverlayClick = (e) => {
    if (modalRef.current && !modalRef.current.contains(e.target)) {
      setPdfFile(null);
    }
  };

  return (
    <div>
      <button onClick={fetchResume}>View Resume</button>
      {pdfFile && (
        <div className="fixed inset-0 z-10 bg-black bg-opacity-10 backdrop-blur-sm flex justify-center items-center transition-opacity duration-300" onClick={handleOverlayClick}>
          <div className="rounded-lg max-w-[100%] max-h-[90%] overflow-y-auto overflow-x-hidden relative transition-transform transform duration-300">
            <span className="absolute top-2 right-4 text-gray-500 text-2xl font-bold cursor-pointer hover:text-black z-20" onClick={() => setPdfFile(null)}>&times;</span>

            <div className='w-[100%] overflow-hidden'>
              <Document file={pdfFile} onLoadSuccess={onDocumentLoadSuccess} className="pdf-viewer">
                {Array.from(new Array(numPages), (el, index) => (
                  <Page
                    key={`page_${index + 1}`}
                    pageNumber={index + 1}
                    width={pageWidth}
                    className="pdf-page"
                  />
                ))}
              </Document>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PdfViewer;
