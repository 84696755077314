import React from 'react';
import axios from 'axios'; // Import Axios
import '../CSS/Home.css';
import '../CSS/UserDash.css';
import '../CSS/RectDash.css';
import home from '../assets/home.png';
import logo1 from '../assets/google.png';
import logo2 from '../assets/microsoft.png';
import logo3 from '../assets/fedex.png';
import logo4 from '../assets/oracle.png';
import logo5 from '../assets/amazon.png';
import UserDash from "../components/users/student/UserDash";
import RectDash from "../components/users/recruiter/RectDash";
import Footer from "../components/footer";
import Jobcard from "../components/users/student/jobcard";
import { NavLink } from 'react-router-dom';
import { FaUser, FaListAlt, FaBriefcase } from 'react-icons/fa';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faHeadset, faUserTie, faTasks, faChartLine, faComments, faBookOpen, faPencilRuler } from '@fortawesome/free-solid-svg-icons';
import CommingSoon from "../Utils/comingSoon"

const Home = (props) => {
  // const UserEmail = JSON.parse(localStorage.getItem('UserEmail'));
  const UserType = JSON.parse(localStorage.getItem('UserType'));
  const { isLoggedIn } = props;

  // Set Axios defaults for credentials
  axios.defaults.withCredentials = true;

  const categories = [
    { icon: faEnvelope, name: 'Marketing' },
    { icon: faHeadset, name: 'Customer Service' },
    { icon: faUserTie, name: 'Human Resource' },
    { icon: faTasks, name: 'Project Management' },
    { icon: faChartLine, name: 'Business Development' },
    { icon: faComments, name: 'Sales & Communication' },
    { icon: faBookOpen, name: 'Teaching & Education' },
    { icon: faPencilRuler, name: 'Design & Creative' },
  ];

  return (
    <div>
      <div className='flex items-center justify-center'>
        {isLoggedIn ? (
          <div>
            {
              UserType === 1 ? <UserDash /> : <RectDash />
            }
          </div>
        ) : (
          // When no One is login
          <div>
            <div className="home-section">
              <div className="home-content">
                <h1>Find the JOB that is perfect for you</h1>
                <p>TalentGrow Global, yet another best job portal for start-up hiring, links the potential candidates with the recruiters. The best feature – you can chat directly and hire a person from anywhere, anytime.</p>
                <div className="button-container">
                  <NavLink to="/signup" className="bttn">
                    Get A Job
                  </NavLink>
                  <NavLink to="/signup" className="bttn">
                    Hire Now
                  </NavLink>
                </div>
              </div>
              <div className="home-image-container">
                <img src={home} alt="Home" className="home-image" />
              </div>
            </div>
            <>
              <p className="subtitle">
                10L+ top companies trust TalentGrow Global for their hiring needs
              </p>
              <div className="logo-container">
                <img src={logo1} alt="google" className="logo" />
                <img src={logo2} alt="microsoft" className="logo" />
                <img src={logo3} alt="fedex" className="logo" />
                <img src={logo4} alt="oracle" className="logo" />
                <img src={logo5} alt="amazon" className="logo" />
              </div>
              {/* < Jobcard /> */}
              <div className="categories-container">
                <h1>Explore By Category</h1>
                <div className="categories-grid">
                  {categories.map((category, index) => (
                    <NavLink to="/signup" key={index}>
                      <div className="category-card">
                        <FontAwesomeIcon icon={category.icon} size="2x" />
                        <h2>{category.name}</h2>
                      </div>
                    </NavLink>
                  ))}
                </div>
                <div className="steps-container">
                  <div className="step-card">
                    <FaUser className="step-icon" />
                    <h3>Register your account</h3>
                    <p>By registering an account, you can use our basic features to find hundreds of exciting jobs.</p>
                  </div>
                  <div className="step-card">
                    <FaListAlt className="step-icon" />
                    <h3>Select your preference</h3>
                    <p>Discover jobs most relevant to you by experience level, salary, location, job type, etc.</p>
                  </div>
                  <div className="step-card">
                    <FaBriefcase className="step-icon" />
                    <h3>Wait for recruiters action</h3>
                    <p>Your next step: waiting for recruiters to review your application and reach out.</p>
                  </div>
                </div>
              </div>
            </>
          </div>
        )}
      </div>

      <Footer />
    </div>
  );
};

export default Home;
