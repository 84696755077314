import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import { format } from 'date-fns';
import './PostedJobCard.css';

const API_PORT = process.env.REACT_APP_API_PORT;

const PostedJobCard = ({ job }) => {
  function formatDate(date) {
    if (!date || isNaN(new Date(date).getTime())) {
      return 'Invalid Date';
    }
    return format(new Date(date), 'yyyy-MM-dd');
  }

  const { opportunityType, companyName, jobProfile, salary_min, salary_max, stipend_max, stipend_min, deadline, jobDescription } = job;

  const UserEmail = localStorage.getItem('UserEmail')?.replace(/"/g, '') || '';
  const [coverImage, setCoverImage] = useState(null);
  const [ProfilePic, setProfilePic] = useState(null);
  const [isExpanded, setIsExpanded] = useState(false);

  const fetchUserImage = useCallback(async () => {
    try {
      const response = await axios.get(`${API_PORT}/fetch_user_img`, {
        params: { email: UserEmail },
        withCredentials: true
      });

      if (response.data.success) {
        setCoverImage(response.data.userimg);
        setProfilePic(response.data.profilePicture);
      } else {
        console.error('Error fetching user image:', response.data.message);
      }
    } catch (error) {
      console.error('Error fetching user image:', error);
    }
  }, [UserEmail]);

  useEffect(() => {
    fetchUserImage();
  }, [fetchUserImage]);

  const renderSalaryOrStipend = () => {
    if (opportunityType === 'job') {
      if (salary_min && salary_max) {
        return `${parseInt(salary_min, 10).toLocaleString()} - ${parseInt(salary_max, 10).toLocaleString()} / Month`;
      } else {
        return 'Not Disclosed';
      }
    } else if (opportunityType === 'internship') {
      if (stipend_min && stipend_max) {
        return `${parseInt(stipend_min, 10).toLocaleString()} - ${parseInt(stipend_max, 10).toLocaleString()} / Month`;
      } else {
        return 'Not Disclosed';
      }
    }
  };

const renderShortDescription = (description) => {
  const wordLimit = 10; // Set the word limit for truncation

  // Handle cases where description might be null or undefined
  if (!description) {
    return 'Description not available'; // or an empty string or any other fallback
  }

  const words = description.split(' ');

  // If the description length is less than or equal to the word limit, return the full description
  if (words.length <= wordLimit) {
    return description;
  }

  // If expanded, show the full description with "Show less" button
  if (isExpanded) {
    return (
      <>
        {description}
        <button
          onClick={(e) => { e.preventDefault(); setIsExpanded(false); }}
          className="text-blue-500 underline ml-1"
        >
          Show less
        </button>
      </>
    );
  }

  // If not expanded, show truncated description with "Read more" button
  return (
    <>
      {words.slice(0, wordLimit).join(' ')}...
      <button
        onClick={(e) => { e.preventDefault(); setIsExpanded(true); }}
        className="text-blue-500 underline ml-1"
      >
        Read more
      </button>
    </>
  );
};

  return (
    <div className="p-4 border rounded-lg shadow-md mb-4">
      <div className="flex items-center mb-2">
        <img
          src={ProfilePic ? ProfilePic : coverImage}
          alt="Profile"
          className="w-20 h-20 rounded-full mr-4"
        />
        <div>
          <h3 className="text-lg font-semibold">{companyName}</h3>
          <p className="text-sm text-gray-600">{jobProfile}</p>
        </div>
      </div>
      <div className="mb-2">
        <p><strong>Opportunity Type:</strong> <span className='capitalize'>{opportunityType}</span></p>
        <p><strong>Compensation:</strong> {renderSalaryOrStipend()}</p>
        <p><strong>Deadline:</strong> {formatDate(deadline)}</p>
      </div>
      <p className="descriptionCard text-gray-700 mb-4">
        {renderShortDescription(jobDescription)}
      </p>
      <div className='flex md:flex-row flex-col gap-4 items-center justify-center'>
        <button className="update-details-btn max-w-[9.74rem]">Edit Applications</button>
        <button className="update-details-btn max-w-[10.2rem]">View Applications</button>
      </div>
    </div>
  );
};

export default PostedJobCard;
