import React, { useState, useEffect } from 'react';
import toast from 'react-hot-toast';
import { MdDeleteForever } from "react-icons/md";
const API_PORT = process.env.REACT_APP_API_PORT;


const EducationDetails = () => {
  const [educationData, setEducationData] = useState({
    degree: '',
    startYear: '',
    endYear: '',
    cgpa: '',
    specialization: '',
    institute: '',
    campus: '',
  });

  const [isFormVisible, setFormVisible] = useState(false);
  const [educationList, setEducationList] = useState([]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEducationData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const fetchEducationData = async () => {
    const UserEmail = localStorage.getItem('UserEmail').replace(/"/g, ''); // Remove double quotes from the email

    try {
      const response = await fetch(`${API_PORT}/get-education`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'x-user-email': UserEmail // Send the user email in the request headers
        }
      });

      const data = await response.json();

      if (data.success) {
        setEducationList(data.data);
        // console.log(data.data);
      } else {
        // console.error('Failed to fetch education details:', data.message);
        toast.error(data.message);
      }
    } catch (error) {
      // console.error('Error:', error);
    }
  };

  useEffect(() => {
    fetchEducationData();
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();

    const UserEmail = localStorage.getItem('UserEmail'); // Get the user email from local storage

    fetch(`${API_PORT}/add-education`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-user-email': UserEmail // Send the user email in the request headers
      },
      body: JSON.stringify(educationData)
    })
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          // console.log('Education Data Submitted:', educationData);
          toast.success(data.message);
          // Update education list with new education data
          setEducationList([...educationList, educationData]);
          // Reset form data
          setEducationData({
            degree: '',
            startYear: '',
            endYear: '',
            cgpa: '',
            specialization: '',
            institute: '',
            campus: '',
          });

          // Hide the form after submission
          setFormVisible(false);
          fetchEducationData();
        } else {
          // console.error('Failed to add education details:', data.message);
          toast.error(data.message);
        }
      })
      .catch(error => {
        toast.error(error.response?.data?.message);
        // console.error('Error:', error);
      });
  };

  const handleAddNewClick = () => {
    setFormVisible(true);
  };

  const handleDelete = async (srno, index) => {
    // Backend deletion
    try {
      const response = await fetch(`${API_PORT}/delete-education?srno=${srno}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json'
        }
      });

      const data = await response.json();

      if (data.success) {
        // Front-end deletion
        const updatedList = [...educationList];
        updatedList.splice(index, 1);
        setEducationList(updatedList);

        toast.success(data.message);
      }
      else {
        toast.error(data.message);
      }
    } catch (error) {
      // console.error('Error:', error);
    }
  };

  return (
    <section className='box'>
      <p className="heading">Education Details</p>
      <div className='education-list'>
        {educationList.map((edu, index) => (
          <div key={index} className='education-item'>
            <div className='delete-icon' onClick={() => handleDelete(edu.srno, index)}>
              <MdDeleteForever size={20} />
            </div>
            <p className='text-[1.2rem] font-semibold leading-8 text-[#002b36]'>Institute: {edu.Institute}</p>
            <p className='text-[1rem] leading-8 text-[#002b36]'>Degree: {edu.Degree}</p>
            <p className='text-[1rem] leading-8 text-[#002b36]'>Start Year: {edu.Start_year}</p>
            <p className='text-[1rem] leading-8 text-[#002b36]'>End Year: {edu.End_year}</p>
            <p className='text-[1rem] leading-8 text-[#002b36]'>CGPA: {edu.CGPA}</p>
            <p className='text-[1rem] leading-8 text-[#002b36]'>Specialization: {edu.Specialization}</p>
          </div>
        ))}
      </div>

      {!isFormVisible && (
        <div className='addButton' onClick={handleAddNewClick}>
          Add New
        </div>
      )}
      {isFormVisible && (
        <form onSubmit={handleSubmit}>
          <div className='col-name'>
            <label>Degree*</label>
            <input
              type="text"
              name="degree"
              value={educationData.degree}
              onChange={handleChange}
              className='input-fields-about'
            />
          </div>

          <div className='row-name'>
            <div className='col-name'>
              <label>Start Year*</label>
              <input
                type="number"
                name="startYear"
                value={educationData.startYear}
                onChange={handleChange}

                className='input-fields'
                placeholder='Start Year'
              />
            </div>

            <div className='col-name'>
              <label>End Year*</label>
              <input
                type="number"
                name="endYear"
                value={educationData.endYear}
                onChange={handleChange}

                className='input-fields'
                placeholder='End Year'
              />
            </div>

            <div className='col-name'>
              <label>CGPA*</label>
              <input
                type="number"
                name="cgpa"
                value={educationData.cgpa}
                onChange={handleChange}

                className='input-fields'
                placeholder='CGPA'
                max={10}
                min={1}
              />
            </div>
          </div>

          <div className='col-name mt-6'>
            <label>Specialization*</label>
            <input
              type="text"
              name="specialization"
              value={educationData.specialization}
              onChange={handleChange}
              className='input-fields-about'
            />
          </div>

          <div className='col-name mt-6'>
            <label>Institute/College Name*</label>
            <input
              type="text"
              name="institute"
              value={educationData.institute}
              onChange={handleChange}
              className='input-fields-about'
            />
          </div>

          <button type="submit" className='update-details-btn'>Add Education</button>
        </form>
      )}
    </section>
  );
};

export default EducationDetails;
